import { Facebook02Icon, NewTwitterIcon, GoogleIcon, Linkedin02Icon } from "../../../icons/icons";

export const IconData = [
    {
        icon: <Facebook02Icon width={20} height={20} color="#3B5998" />,
        link: '',
        className: "facebook-icon",
    },
    {
        icon: <NewTwitterIcon width={20} height={20} color="#444050" />,
        link: '',
        className: "twiter-icon",
    },
    {
        icon: <Linkedin02Icon width={20} height={20} color="#0073AF" />,
        link: '',
        className: "linkedin-icon",
    },
    {
        icon: <GoogleIcon width={20} height={20} color="#DD4B39" />,
        link: '',
        className: "google-icon",
    }

]