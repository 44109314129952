import React from "react";
import { Form } from "react-bootstrap";

export default function SelectBox({
  size,
  name,
  value,
  onChange,
  ariaLabel,
  label,
  style,
  staticOption,
  data,
  className,
  disabled
}) {
  return (
    <Form.Select
      name={name}
      size={size}
      label={label}
      value={value}
      onChange={onChange}
      style={style}
      aria-label={ariaLabel}
      className={`custome-selectbox ${className}`}
      disabled={disabled}
    >
      <option>{staticOption}</option>
      {data.map((item, i) => {
        return (
          <option key={i} value={item.value}>
            {item.label}
          </option>
        );
      })}

      {/* <option value="2">Two</option>
            <option value="3">Three</option> */}
    </Form.Select>
  );
}
