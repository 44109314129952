import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Button,
  Toast,
  FormCheck as Switch,
  Tabs,
  Tab,
  ToastContainer,
} from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { updateEvent, fetchEvents } from "../../../store/eventsSlice";
import { editResellerSchema } from "../../../schema/validationSchemas";
import styles from "./style.module.css";
import { CheckmarkBadge04Icon } from "../../../icons/icons";

export default function Edit({ show, handleClose, resellerId }) {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");
  const [key, setKey] = useState("reseller");
  const dispatch = useDispatch();
  const itemsPerPage = 2;

  const handleModalClose = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const [reseller, setReseller] = useState({
    name: "",
    country_code: "",
    mobile_number: "",
    email: "",
    password: "",
    referral_code: "",
    credits: 0,
    country_id: "",
    is_active: true,

    company_name: "",
    company_website: "",
    company_address: "",
    company_logo: "",
    company_logo_new: null,
  });

  const resellerDetails = useSelector((state) =>
    state.reseller.resellers.find((r) => r.id === resellerId)
  );

  useEffect(() => {
    if (show && resellerId && resellerDetails) {
      const updatedReseller = {
        name: resellerDetails.name || "",
        country_code: resellerDetails.country_code || "",
        mobile_number: resellerDetails.mobile_number || "",
        email: resellerDetails.email || "",
        referral_code: resellerDetails.referral_code || "",
        credits: resellerDetails.credits || 0,
        country_id: resellerDetails.country_id || "",
        is_active: resellerDetails.is_active || false,
        company_name: resellerDetails.company?.name || "",
        company_website: resellerDetails.company?.website || "",
        company_address: resellerDetails.company?.address || "",
        company_logo: resellerDetails.company?.logo || null,
        company_logo_new: null,
      };
      setReseller(updatedReseller);
    }
  }, [show, resellerId, resellerDetails]);

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Reseller</Modal.Title>
        </Modal.Header>
        <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
          <Tab
            eventKey="reseller"
            title="Reseller"
            tabClassName={
              key === "reseller" ? styles.navLinkActive : styles.navLink
            }
          ></Tab>
          <Tab
            eventKey="companyDetails"
            title="Company Details"
            tabClassName={
              key === "companyDetails" ? styles.navLinkActive : styles.navLink
            }
          ></Tab>
        </Tabs>
        <Formik
          enableReinitialize
          initialValues={reseller}
          validationSchema={editResellerSchema}
          onSubmit={async (values, { setSubmitting, resetForm, setErrors }) => {
            const formData = new FormData();
            formData.append("id", resellerId);
            Object.keys(values).forEach((key) => {
              if (key === "company_logo" && values[key]) {
                formData.append(key, values[key]);
              } else {
                formData.append(key, values[key] || "");
              }
              if (key === "is_active") {
                formData.append(key, values[key] === true ? "true" : "false");
              }
            });

            formData.append("_method", "PUT");

            dispatch(updateEvent(formData))
              .then((res) => {
                console.log("response", res.payload.message);
                if (res.payload.success) {
                  console.log("Success!");
                  setSnackbarMessage(res.payload.message);
                  setSnackbarVariant("toast-success");
                  console.log("Before setShowSnackbar:", showSnackbar);
                  setShowSnackbar(true);
                  console.log("After setShowSnackbar:", showSnackbar);
                  dispatch(
                    fetchEvents({
                      offset: 0,
                      limit: itemsPerPage,
                      search: "",
                    })
                  );
                  console.log(
                    "Snackbar should show:",
                    showSnackbar,
                    snackbarMessage
                  );
                  resetForm();
                  handleClose();
                } else {
                  console.log("Failed!");
                  console.log("errors", res.payload.data);
                  setSnackbarMessage(res.payload.message);
                  setErrors(res.payload.data);
                  setSnackbarVariant("toast-danger");
                  setShowSnackbar(true);
                }
              })
              .catch((error) => {
                console.error("Error:", error);
                setSnackbarMessage(
                  "Failed to update reseller: " + error.message
                );
                setSnackbarVariant("toast-danger");
                setShowSnackbar(true);
              })

              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ errors, touched, handleChange, setFieldValue, values }) => (
            <Form encType="multipart/form-data">
              <Modal.Body>
                {key === "reseller" && (
                  <>
                    <Field
                      name="name"
                      type="text"
                      className={`form-control ${
                        errors.name && touched.name ? "is-invalid" : ""
                      }`}
                      placeholder="Enter name"
                      onChange={handleChange}
                    />
                    {errors.name && touched.name ? (
                      <div className="invalid-feedback">{errors.name}</div>
                    ) : null}
                    <br />

                    <Field
                      name="country_code"
                      type="text"
                      className={`form-control ${
                        errors.country_code && touched.country_code
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter country code"
                      onChange={handleChange}
                    />
                    {errors.country_code && touched.country_code ? (
                      <div className="invalid-feedback">
                        {errors.country_code}
                      </div>
                    ) : null}
                    <br />

                    <Field
                      name="mobile_number"
                      type="text"
                      className={`form-control ${
                        errors.mobile_number && touched.mobile_number
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter mobile number"
                      onChange={handleChange}
                    />
                    {errors.mobile_number && touched.mobile_number ? (
                      <div className="invalid-feedback">
                        {errors.mobile_number}
                      </div>
                    ) : null}
                    <br />

                    <Field
                      name="email"
                      type="email"
                      className={`form-control ${
                        errors.email && touched.email ? "is-invalid" : ""
                      }`}
                      placeholder="name@example.com"
                      onChange={handleChange}
                    />
                    {errors.email && touched.email ? (
                      <div className="invalid-feedback">{errors.email}</div>
                    ) : null}
                    <br />

                    <Field
                      name="referral_code"
                      type="text"
                      className={`form-control ${
                        errors.referral_code && touched.referral_code
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter referral code"
                      onChange={handleChange}
                    />
                    {errors.referral_code && touched.referral_code ? (
                      <div className="invalid-feedback">
                        {errors.referral_code}
                      </div>
                    ) : null}
                    <br />

                    <Field
                      name="credits"
                      type="number"
                      className={`form-control ${
                        errors.credits && touched.credits ? "is-invalid" : ""
                      }`}
                      placeholder="Enter credits"
                      onChange={handleChange}
                    />
                    {errors.credits && touched.credits ? (
                      <div className="invalid-feedback">{errors.credits}</div>
                    ) : null}
                    <br />

                    <Field
                      as="select"
                      name="country_id"
                      className={`form-control ${
                        errors.country_id && touched.country_id
                          ? "is-invalid"
                          : ""
                      }`}
                      onChange={handleChange}
                    >
                      <option value="">Select Country</option>
                      <option value="1">India</option>
                      <option value="2">Australia</option>
                    </Field>
                    {errors.country_id && touched.country_id ? (
                      <div className="invalid-feedback">
                        {errors.country_id}
                      </div>
                    ) : null}

                    <Switch
                      id="is_active"
                      type="switch"
                      className="mt-3"
                      label={values.is_active ? "Active" : "Inactive"}
                      checked={values.is_active}
                      onChange={(e) =>
                        setFieldValue("is_active", e.target.checked)
                      }
                    />
                    <br />
                  </>
                )}

                {key === "companyDetails" && (
                  <>
                    <Field
                      name="company_name"
                      type="text"
                      className={`form-control ${
                        errors.company_name && touched.company_name
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter company name"
                      onChange={handleChange}
                    />
                    {errors.company_name && touched.company_name ? (
                      <div className="invalid-feedback">
                        {errors.company_name}
                      </div>
                    ) : null}
                    <br />

                    <Field
                      name="company_website"
                      type="text"
                      className={`form-control ${
                        errors.company_website && touched.company_website
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter website"
                      onChange={handleChange}
                    />
                    {errors.company_website && touched.company_website ? (
                      <div className="invalid-feedback">
                        {errors.company_website}
                      </div>
                    ) : null}
                    <br />

                    <Field
                      name="company_address"
                      type="text"
                      className={`form-control ${
                        errors.company_address && touched.company_address
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter address"
                      onChange={handleChange}
                    />
                    {errors.company_address && touched.company_address ? (
                      <div className="invalid-feedback">
                        {errors.company_address}
                      </div>
                    ) : null}
                    <br />

                    <div className="mb-3">
                      <label htmlFor="company_logo_new" className="form-label">
                        Upload Logo
                      </label>
                      <input
                        id="company_logo_new"
                        name="company_logo_new"
                        type="file"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          setFieldValue("company_logo_new", file);
                        }}
                        className={`form-control ${
                          errors.company_logo_new && touched.company_logo_new
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      {errors.company_logo_new && touched.company_logo_new ? (
                        <div className="invalid-feedback">
                          {errors.company_logo_new}
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                  Close
                </Button>
                <Button type="submit" variant="primary">
                  Update
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}
