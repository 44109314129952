import React, {
  Fragment,
  useCallback,
  // useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CommonFormLabel from "../../../../components/common-form-label";
import Switch from "../../../../components/switch/index";
import Input from "../../../../components/input/index";
import SelectBox from "../../../../components/select-box/index";
import ColorPicker from "react-best-gradient-color-picker";
import CommomButton from "../../../../components/common-button/index";
import Range from "../../../../components/range/index";
import {
  Row,
  Col,
  // Card,
  // CardBody,
  // CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Image as Img,
} from "react-bootstrap";
import {
  // ButtonDropdown,
  // DropdownMenu,
  // DropdownToggle,
  // Input,
  Label,
  // Progress,
  // UncontrolledTooltip,
} from "reactstrap";
// import classNames from "classnames";
// import {
//   // Calendar,
//   // ArrowLeft,
//   ChevronLeft,
//   ChevronRight,
//   Download,
//   Edit,
//   Plus,
//   RefreshCcw,
//   // Send,
//   Trash2,
//   ZoomIn,
//   ZoomOut,
// } from "react-feather";
import { PDFDocument, rgb } from "pdf-lib";
import {
  // Document,
  // Page,
  pdfjs,
} from "react-pdf";
import { Image, Layer, Stage, Text } from "react-konva";
import fontkit from "@pdf-lib/fontkit";
import useImage from "use-image";
import TimesNewRoman from "../Fonts/timesNewRoman.ttf";
// import { fontStyleOptions } from "../Fonts/FontStyle";
import { fontStyleOptions } from "../Fonts/FontStyle";
// import {}
import {
  // useLocation,
  useParams,
  // useNavigate
} from "react-router-dom";
import {
  convertBase64ToFile,
  // dateTimeToUCT,
  pdfToBase64File,
} from "../common/Common";
import "../styleModule.css";
import moment from "moment";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  ColorPickerIcon,
  ReloadIcon,
  FileDownloadIcon,
  SearchMinusIcon,
  SearchAddIcon,
} from "../../../../icons/icons";
// import { Toast } from "react-bootstrap";

// import { sendLastMessage, sendMessage } from "../../../../@core/api/eventApi";
// import ComponentSpinner from "../../../../@core/components/spinner/Loading-spinner";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
// import { notification } from "../../../../@core/constants/notification";
// import { notification } from "./common/notification";

const URLImage = ({ image }) => {
  const [img] = useImage(image);
  return <Image image={img} className="position-absolute" x={0} y={0} />;
};
const loadFont = async (name, url) => {
  const font = new FontFace(name, `url(${url})`);
  await font.load();
  document.fonts.add(font);
  console.log(`${name} font loaded`);
};

const EditPdf = ({
  // stepper,
  tableData,
  csvKeys,
  totalPages,
  pagesUrl,
  file,
  fileType,
  messageData,
  formData,
  setFormData,
  setPos,
  pos,
  inputdata,
  formError,
  campaingDetails,
  isTestOpen,
  setIsTestOpen,
  handleTestClick,
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const { clientId, id } = useParams();
  console.log("CamID : ", id, "Client iD:: ", clientId);

  // const navigate = useNavigate();
  const stageRef = useRef(null);
  // const { state } = useLocation();
  // const MySwal = withReactContent(Swal);

  const [currentPage, setCurrentPage] = useState(1);
  // const [optionsVisible, setOptionsVisible] = useState({
  //   visible: false,
  //   key: -1,
  // });
  const [testMobile, setTestMobile] = useState("");
  // const [sendMessageLoading, setSendMessageLoading] = useState(false);
  // const [
  //   messageProgress,
  //   // setMessageProgress
  // ] = useState(0);
  // const [testProgress, setTestProgress] = useState(false);
  const [messagePreview, setMessagePreview] = useState("");

  console.log(messagePreview);

  // Color Pickker Toogle
  // const [color, setColor] = useState("rgba(255,255,255,1)");

  const [colorPicker, SetColorPicker] = useState(false);
  const colorPickerRef = useRef(null);

  const handlechangeOpen = () => {
    SetColorPicker((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target)
      ) {
        SetColorPicker(false); // Close the color picker if clicked outside
      }
    };

    if (colorPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [colorPicker]);
  // Color Pickker Toogle

  const expiry_date = useSelector((state) => state.auth.user.expiry_date);
  const company_id = useSelector((state) => state.auth.user.company_id);

  const [modalSchedule, setModalSchedule] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // State for managing zoom level
  const containerRef = useRef(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const maxZoom = 3;
  const minZoom = 0;
  // const [stagePosition, setStagePosition] = useState({ x: 0, y: 0 });
  const [selectedTextKey, setSelectedTextKey] = useState(null);
  const [stageSize, setStageSize] = useState({
    width: 800,
    height: 649,
  });

  useEffect(() => {
    fontStyleOptions.forEach((fontOption) => {
      loadFont(fontOption.value, fontOption.file); // Load each font dynamically
    });
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      // Define the move amount based on whether Ctrl is pressed
      const moveAmount = e.ctrlKey ? 10 : 0.1;

      const isArrowKey = [
        "ArrowUp",
        "ArrowDown",
        "ArrowLeft",
        "ArrowRight",
      ].includes(e.key);

      if (
        selectedTextKey &&
        pos[selectedTextKey]?.page === currentPage &&
        isArrowKey
      ) {
        e.preventDefault();
        // Right Arrow
        if (e.key === "ArrowRight") {
          setPos((prevPos) => ({
            ...prevPos,
            [selectedTextKey]: {
              ...prevPos[selectedTextKey],
              x: prevPos[selectedTextKey].x + moveAmount,
            },
          }));
        }

        // Left Arrow
        if (e.key === "ArrowLeft") {
          setPos((prevPos) => ({
            ...prevPos,
            [selectedTextKey]: {
              ...prevPos[selectedTextKey],
              x: prevPos[selectedTextKey].x - moveAmount,
            },
          }));
        }

        // Up Arrow
        if (e.key === "ArrowUp") {
          setPos((prevPos) => ({
            ...prevPos,
            [selectedTextKey]: {
              ...prevPos[selectedTextKey],
              y: prevPos[selectedTextKey].y - moveAmount,
            },
          }));
        }

        // Down Arrow
        if (e.key === "ArrowDown") {
          setPos((prevPos) => ({
            ...prevPos,
            [selectedTextKey]: {
              ...prevPos[selectedTextKey],
              y: prevPos[selectedTextKey].y + moveAmount,
            },
          }));
        }
      }
    };

    // Add the event listener
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [pos, setPos, currentPage, selectedTextKey]);

  // Function to handle zoom in
  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.01, maxZoom));
  };

  // Function to handle zoom out
  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.01, minZoom));
  };

  // const handleWheel = (e) => {
  //   e.evt.preventDefault();

  //   const scaleBy = 1.05;
  //   const stage = stageRef.current;
  //   const oldScale = stage.scaleX();

  //   if (e.evt.ctrlKey) {
  //     // Zoom when Ctrl is pressed
  //     const mousePointTo = {
  //       x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
  //       y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
  //     };
  //     const newScale =
  //       e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
  //     setZoomLevel(newScale);
  //     stage.scale({ x: newScale, y: newScale });
  //     const newPos = {
  //       x:
  //         -(mousePointTo.x - stage.getPointerPosition().x / newScale) *
  //         newScale,
  //       y:
  //         -(mousePointTo.y - stage.getPointerPosition().y / newScale) *
  //         newScale,
  //     };
  //     stage.position(newPos);
  //   } else {
  //     // Scroll behavior
  //     const newPosition = {
  //       x: stagePosition.x - e.evt.deltaX,
  //       y: stagePosition.y - e.evt.deltaY,
  //     };
  //     setStagePosition(newPosition);
  //     stage.position(newPosition);
  //   }
  // };
  const updateStageSize = useCallback(() => {
    if (containerRef.current && pagesUrl.length > 0) {
      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;

      // Get the current page dimensions
      const pageWidth = pagesUrl[currentPage - 1]?.width || containerWidth;
      const pageHeight = pagesUrl[currentPage - 1]?.height || containerHeight;

      // Calculate scale factor to fit the page into the container
      const scaleX = containerWidth / pageWidth;
      const scaleY = containerHeight / pageHeight;
      const scaleFactor = Math.min(scaleX, scaleY); // Use the smaller scale to fit both width and height

      setZoomLevel(scaleFactor); // Set initial zoom level to fit the document

      setStageSize({
        width: pageWidth * scaleFactor,
        height: pageHeight * scaleFactor,
      });

      // Adjust the default font size based on document size
      // const scaledFontSize = 18 * scaleFactor; // Adjust the default font size proportionally
      // setDefaultFontSize(Math.max(12, scaledFontSize)); // Ensure font size doesn't get too small
    }
  }, [pagesUrl, currentPage]);

  useEffect(() => {
    // Update the stage size when the window or container changes size or page changes
    updateStageSize();
    window.addEventListener("resize", updateStageSize);
    return () => window.removeEventListener("resize", updateStageSize);
  }, [updateStageSize]);

  const toggleSchedule = () => setModalSchedule(!modalSchedule);

  const toggleTestModal = () => {
    setTestMobile("");
    setIsSubmitting(false);
    setIsTestOpen(!isTestOpen);
  };
  // const toggleOptionVisible = (key) =>
  //   setOptionsVisible(
  //     optionsVisible?.visible === true
  //       ? { visible: false, key: -1 }
  //       : { visible: true, key: key }
  //   );

  // function onDocumentLoadSuccess({ numPages }) {
  //   console.log("numpagessss", numPages);
  // }

  useEffect(() => {
    let newMessage = messageData;
    csvKeys.forEach((item) => {
      newMessage = newMessage.replaceAll(
        `[[${item}]]`,
        tableData[0][item].trim()
      );
    });
    setMessagePreview(newMessage);
  }, [messageData, csvKeys, tableData]);

  const sendTestMessage = async () => {
    if (!testMobile) {
      //   notification({
      //     type: "error",
      //     title: "Please enter Mobile number",
      //   });
    } else {
      setIsSubmitting(true);
      // setSendMessageLoading(true);
      // setTestProgress(true);
      let media = [];
      if (file) {
        const pdfBytes = await changeFont(tableData[0]);
        if (fileType === "application/pdf") {
          media = new File([pdfBytes], `${tableData[0].name}.pdf`, {
            type: "application/pdf;charset=utf-8",
          });
        } else {
          const dataURL = await pdfToBase64File(pdfBytes, fileType);
          media = await convertBase64ToFile(dataURL);
        }
      }
      let newMessage = messageData;
      csvKeys.forEach((item) => {
        newMessage = newMessage.replaceAll(
          `[[${item}]]`,
          tableData[0][item].replaceAll(/;/g, ",").trim()
        );
      });
      let request = new FormData();
      // request.append("connectionId", state.connectionId);
      request.append("campaign_id", id);
      request.append("clientId", clientId);
      request.append("company_id", company_id);
      request.append("message", JSON.stringify(newMessage));
      request.append(
        "delay",
        formData?.delaySecond ? formData?.delaySecond : 0
      );
      // request.append("schedule", null);
      request.append("numbers", testMobile);
      request.append("name", "Test Message");
      // request.append("total_message", 1);
      request.append(
        "file_name",
        `${tableData[0].name}${
          fileType === "application/pdf"
            ? ".pdf"
            : fileType === "image/png"
            ? ".png"
            : ".jpeg"
        }`
      );
      // request.append(
      //   "caption",
      //   formData?.captionData ? formData?.captionData : ""
      // );
      request.append("media", media);

      await axios.post(
        `${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/send-test-message`,
        request
      );

      //   let response = await sendMessage(request);
      setTestMobile("");

      setIsSubmitting(false);
      // setSendMessageLoading(false);
      // setTestProgress(false);
      toggleTestModal();
      setTestMobile("");
    }
  };

  console.log(pagesUrl[currentPage - 1]?.height);

  const changeFont = async (data) => {
    const existingPdfBytes = await fetch(URL.createObjectURL(file)).then(
      (res) => res.arrayBuffer()
    );
    const pdfDocs1 = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDocs1.getPages();
    await pdfDocs1.registerFontkit(fontkit);
    let embeddedFonts = [];
    for (let key in pos) {
      const fontObject = pos[key];
      const fontBytes = await fetch(fontObject.fontFile).then((res) =>
        res.arrayBuffer()
      );

      console.log(" fontBytes ::::: ", fontBytes);
      // let font = await fontkit.create(fontBytes);
      const embeddedFont = await pdfDocs1.embedFont(fontBytes, {
        subset: true,
      });
      embeddedFonts.push(embeddedFont);
    }
    let index = 0;
    for (let key in pos) {
      const page = pages[pos[key].page - 1];
      const {
        // width,
        height,
      } = page.getSize();
      // const textWidth = embeddedFonts[index]?.widthOfTextAtSize(
      //   data[key],
      //   pos[key]?.size
      // );
      const textHeight = embeddedFonts[index].sizeAtHeight(pos[key]?.size);
      let x = pos[key].x;
      console.log("COLOR HHAHAHHAHAH:::: ", pos[key]?.color);
      page.drawText(data[key].replaceAll(/;/g, ","), {
        x: x,
        y:
          height -
          (pos[key].y +
            textHeight -
            (textHeight >= 46
              ? 7
              : textHeight >= 30
              ? 6
              : textHeight >= 12
              ? 3
              : 1)),
        size: pos[key]?.size,
        font: embeddedFonts[index],
        color: rgb(
          pos[key]?.color[0] / 255,
          pos[key]?.color[1] / 255,
          pos[key]?.color[2] / 255
        ),
      });
      index++;
    }
    const pdfBytes = await pdfDocs1.save();
    return pdfBytes;
  };

  const downloadPDF = async () => {
    const link = document.createElement("a");
    const pdfBytes = await changeFont(tableData[0]);
    let file = new File([pdfBytes], "test-Certificate.pdf", {
      type: "application/pdf;charset=utf-8",
    });
    if (fileType === "application/pdf") {
      const encodedUri = window.URL.createObjectURL(file);
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${tableData[0].name}.pdf`);
    } else {
      const dataURL = await pdfToBase64File(
        window.URL.createObjectURL(file),
        fileType
      );
      link.setAttribute("href", dataURL);
      link.setAttribute(
        "download",
        `${tableData[0].name}${fileType === "image/png" ? ".png" : ".jpeg"}`
      );
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDragEnd = (e, key) => {
    // const textNode = e.target;
    // setPos({
    //   ...pos,
    //   [key]: {
    //     ...pos[`${key}`],
    //     x: textNode.x(),
    //     y: textNode.y(),
    //   },
    // });
    // setSelectedTextKey(key);
    console.log("Text Node :::::: ", e.target);
    const textNode = e.target;
    setPos((prevPos) => ({
      ...prevPos,
      [key]: {
        ...prevPos[key],
        x: textNode.x(),
        y: textNode.y(),
      },
    }));
    setSelectedTextKey(key);
  };

  const componentToHex = (c) => {
    var hex = c?.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  const rgbToHex = (color) =>
    "#" +
    componentToHex(color[0]) +
    componentToHex(color[1]) +
    componentToHex(color[2]);

  console.log("Font ::::::: ", pos);
  console.log("Selected ::::::: ", selectedTextKey);
  console.log("CUrrunt page ::::::: ", currentPage);

  return (
    <Fragment>
      <>
        <Row>
          <Col xs={12} lg={12} xl={12} xxl={4}>
            <div className="bg-white rounded-2 common-shadow px-3 py-4 mb-4">
              <div className="send-invitaion-left-content">
                <h5 className="fw-medium">Customize PDF</h5>
                <div className="my-4 h-divider "></div>
                {tableData &&
                  tableData?.length > 0 &&
                  Object.keys(tableData[0]).map((data, index) => {
                    console.log("Datatatatatat :::::::: ", pos[data]);
                    return (
                      index !== 1 && (
                        <>
                          <div className="d-flex align-items-center justify-content-between gap-3 mb-4">
                            <p className="fw-medium">{data}</p>
                            <div className="d-flex align-items-center gap-2">
                              <p className="fw-medium">Edit</p>
                              <Switch
                                type="switch"
                                id="custom-switch"
                                className="sm-switch"
                                checked={pos[data] ? true : false}
                                onChange={() => {
                                  setPos((prevPos) => {
                                    const newPos = { ...prevPos };
                                    if (newPos[data]) {
                                      delete newPos[data];
                                    } else {
                                      newPos[data] = {
                                        text: tableData[0][data] || data,
                                        page: currentPage,
                                        x: 1,
                                        y: 1,
                                        color: [0, 0, 0],
                                        size: 18,
                                        fontStyle: "TimesNewRoman",
                                        fontFile: TimesNewRoman,
                                      };
                                    }
                                    return newPos;
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {pos[data] && (
                            <div>
                              <div>
                                <CommonFormLabel title="Select Font" />
                                <SelectBox
                                  staticOption="Please Choose a Fonts..."
                                  data={fontStyleOptions}
                                  value={pos[data]?.fontStyle || ""}
                                  onChange={(e) => {
                                    setPos((prevPos) => {
                                      const newPos = { ...prevPos }; // Clone the previous state object
                                      newPos[data] = {
                                        // Clone the nested object to avoid mutating the original
                                        ...prevPos[data],
                                        fontStyle: e.target.value,
                                        fontFile: fontStyleOptions.find(
                                          (option) =>
                                            option.value === e.target.value
                                        ).file,
                                      };
                                      return newPos; // Return the new updated state object
                                    });
                                  }}
                                />
                              </div>
                              <div className="grid mt-3 gap-3">
                                <div className="g-col-6">
                                  <CommonFormLabel title="Font Size" />
                                  <Input
                                    type="number"
                                    placeholder="Enter Font Size"
                                    className="shadow-none"
                                    value={pos[data].size}
                                    onChange={(e) => {
                                      const newSize = parseInt(
                                        e.target.value.replace(/[^0-9]/g, "")
                                      );
                                      // Ensure we're not modifying the original object
                                      setPos((prevPos) => {
                                        // Return a new copy of the pos object, and update the specific property immutably
                                        return {
                                          ...prevPos,
                                          [data]: {
                                            ...prevPos[data],
                                            size: newSize, // Update the font size immutably
                                          },
                                        };
                                      });
                                    }}
                                  />
                                </div>
                                <div className="g-col-6">
                                  <CommonFormLabel title="Select Color" />

                                  <div className="position-relative">
                                    <Input
                                      type="text"
                                      // placeholder="#000000"
                                      className="shadow-none custom-color-picker"
                                      minLength={4}
                                      maxLength={7}
                                      value={rgbToHex(pos[data]?.color)}
                                      // onChange={(e) => {
                                      //   let hexValue = e.target.value;
                                      //   let rgbValue = [
                                      //     ("0x" + hexValue[1] + hexValue[2]) |
                                      //       0,
                                      //     ("0x" + hexValue[3] + hexValue[4]) |
                                      //       0,
                                      //     ("0x" + hexValue[5] + hexValue[6]) |
                                      //       0,
                                      //   ];
                                      //   setPos((prevPos) => {
                                      //     const newPos = { ...prevPos };
                                      //     newPos[data].color = rgbValue;
                                      //     return newPos;
                                      //   });
                                      // }}
                                    />
                                    <div className="position-absolute top-50 end-0 translate-middle-y me-1 z-15">
                                      <div className=" position-relative">
                                        <CommomButton
                                          icon={
                                            <ColorPickerIcon
                                              width={18}
                                              height={18}
                                              color="#ffffff"
                                              strokeWidth="2"
                                            />
                                          }
                                          onClick={handlechangeOpen}
                                          className="btn-primary primary-shadow py-1 px-2 "
                                        />
                                        {colorPicker && (
                                          <div
                                            className=" position-absolute top-100 end-0 mt-3"
                                            ref={colorPickerRef}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <ColorPicker
                                              className="border rounded-2 shadow-none"
                                              // value={color}
                                              onChange={(color) => {
                                                console.log("COCOCOCOCOCO : ",color);
                                                const rgbValues = color.match(
                                                  /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
                                                );
                                                if (rgbValues) {
                                                  const newColor = [
                                                    parseInt(rgbValues[1]),
                                                    parseInt(rgbValues[2]),
                                                    parseInt(rgbValues[3]),
                                                  ];
                                                  if (rgbValues[4]) {
                                                    newColor.push(
                                                      parseFloat(rgbValues[4])
                                                    );
                                                  }
                                                  setPos({
                                                    ...pos,
                                                    [data]: {
                                                      ...pos[`${data}`],
                                                      color: newColor,
                                                    },
                                                  });
                                                }
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )
                    );
                  })}
              </div>
            </div>
          </Col>
          <Col xs={12} lg={12} xl={12} xxl={8}>
            <div className="bg-white rounded-2 common-shadow px-3 py-4 mb-4">
              <div className="send-invitaion-right-content">
                <div className="d-flex align-items-center justify-content-between gap-4 mb-4">
                  <h5 className="fw-medium">PDF Preview</h5>
                  <div className="d-flex align-items-center gap-3">
                    <CommomButton
                      icon={
                        <ReloadIcon
                          width={15}
                          height={15}
                          color="#7367F0"
                          strokeWidth="2.5"
                        />
                      }
                      title="Refresh"
                      className="btn-outline-primary bg-transparent "
                      subClassName="gap-2"
                    />
                    <CommomButton
                      icon={
                        <FileDownloadIcon
                          width={15}
                          height={15}
                          color="#7367F0"
                          strokeWidth="2.5"
                        />
                      }
                      title="Download"
                      className="btn-outline-primary bg-transparent "
                      subClassName="gap-2"
                      onClick={() => downloadPDF()}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-start justify-content-between gap-3 ">
                  <div
                    className=" flex-grow-1 d-inline-block"
                    ref={containerRef}
                  >
                    <Stage
                      id="stage"
                      ref={stageRef}
                      width={stageSize.width}
                      height={stageSize.height}
                      className="overflow-hidden pdf-preview-content"
                      scaleX={zoomLevel}
                      scaleY={zoomLevel}
                      // onWheel={handleWheel}
                      draggable
                    >
                      <Layer>
                        <URLImage image={pagesUrl[currentPage - 1]?.base64} />
                        {Object.keys(pos).map((key) => {
                          if (pos[key]?.page === currentPage) {
                            return (
                              <Text
                                className="position-absolute"
                                text={tableData[0][key]}
                                x={pos[key]?.x}
                                y={pos[key]?.y}
                                draggable={pos[key] ? true : false}
                                onDragEnd={(e) => {
                                  handleDragEnd(e, key);
                                }}
                                // onClick={() => {
                                //   console.log("cbchjsabjhcasjbcjh : ");
                                //   setSelectedTextKey(key)
                                //   console.log("cbchjsabjhcasjbcjh : ", selectedTextKey);
                                // }}
                                // fill={pos[key]?.color}
                                fill={`rgba(${pos[key]?.color[0]} ${pos[key]?.color[1]} ${pos[key]?.color[2]})`}
                                fontSize={pos[key]?.size}
                                fontFamily={pos[key]?.fontStyle}
                                // font
                              />
                            );
                          } else {
                            return null;
                          }
                        })}
                      </Layer>
                    </Stage>
                  </div>

                  <div className="d-flex align-items-center justify-content-start gap-3 flex-column flex-shrink-1  overflow-y-scroll img-view-content">
                    {pagesUrl.map((item, i) => {
                      return (
                        <Img
                          key={i}
                          src={item.base64}
                          className=" object-fit-contain img-view cursor-pointer"
                          height={132}
                          width={100}
                          alt="PDF Images"
                          onClick={() => setCurrentPage(i + 1)}
                        />
                      );
                    })}
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center mt-4">
                  <div className="d-inline-block mx-auto">
                    <div className="d-flex align-items-center p-2  rounded-pill border gap-3">
                      <span className=" cursor-pointer" onClick={handleZoomOut}>
                        <SearchMinusIcon
                          width={20}
                          height={20}
                          color="#444050"
                          strokeWidth="2"
                        />
                      </span>

                      <Range />

                      <span className=" cursor-pointer" onClick={handleZoomIn}>
                        <SearchAddIcon
                          width={20}
                          height={20}
                          color="#444050"
                          strokeWidth="2"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {/* {file ? (
          <Row>
            <Col xl="12" className="mb-25 ">
              <Row className="pt-5">
                <Col md="3" className="border_rightcol pt-2">
                  <div className="d-flex justify-content-between align-items-center fw-bold ps-1 mb-2">
                    <h4>Add Values</h4>
                    <div className="d-flex justify-content-end">
                      <Button
                        id="refreshBtn"
                        className="btn-icon"
                        color="primary"
                        size="sm"
                        outline
                        style={{ margin: "5px" }}
                        onClick={async () => {
                          setPos({});
                          setCurrentPage(1);
                        }}
                      >
                        <RefreshCcw size={15} className="align-middle me-0" />
                        <UncontrolledTooltip
                          placement="bottom"
                          target="refreshBtn"
                        >
                          Refresh
                        </UncontrolledTooltip>
                      </Button>
                      <Button
                        id="downloadBtn"
                        className="btn-icon"
                        color="primary"
                        size="sm"
                        outline
                        style={{ margin: "5px" }}
                        onClick={() => downloadPDF()}
                      >
                        <Download size={15} className="align-middle ms-0" />
                        <UncontrolledTooltip
                          placement="bottom"
                          target="downloadBtn"
                        >
                          Download
                        </UncontrolledTooltip>
                      </Button>
                    </div>
                  </div>
                  <div className="edit_add_card mt-3">
                    <div className="cardbody_data">
                      {tableData &&
                        tableData?.length > 0 &&
                        Object.keys(tableData[0]).map((data, index) => {
                          // console.log("DATA :::::::: ", tableData[0][data]);
                          // setPos({
                          //   ...pos,
                          //   [data]: {
                          //     text: (
                          //       tableData[0][data] || data
                          //     ).replaceAll(/;/g, ","),
                          //   },
                          // });
                          return (
                            index !== 1 && (
                              <>
                                <div
                                  key={index}
                                  className={classNames(
                                    "d-flex justify-content-start align-items-center",
                                    {
                                      "mt-1": index === 0,
                                      // eslint-disable-next-line no-dupe-keys
                                      "mt-1": index !== 0,
                                    }
                                  )}
                                >
                                  <Col md="8">
                                    <p
                                      className="mb-0 text-start"
                                      style={{
                                        wordBreak: "break-word",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {(tableData[0][data] || data)?.replaceAll(
                                        /;/g,
                                        ","
                                      )}
                                    </p>
                                  </Col>
                                  <Col
                                    md="4"
                                    className="d-flex justify-content-end align-items-center"
                                  >
                                    {!pos[data] && (
                                      <Button
                                        id="addVariable"
                                        className="btn-icon"
                                        color="primary"
                                        size="sm"
                                        outline
                                        style={{
                                          margin: "5px",
                                          padding: "3px",
                                        }}
                                        onClick={async () => {
                                          const pageWidth =
                                            pagesUrl[currentPage - 1]?.width ||
                                            600; // Default width if not available
                                          const pageHeight =
                                            pagesUrl[currentPage - 1]?.height ||
                                            800; // Default height if not available

                                          console.log(
                                            pagesUrl[currentPage - 1]?.width,
                                            pagesUrl[currentPage - 1]?.height
                                          );

                                          // Calculate an appropriate initial font size as a percentage of the page dimensions
                                          const baseFontSize = 18; // The reference font size for typical dimensions
                                          const referenceWidth = 600; // Reference width for the base font size
                                          const referenceHeight = 800; // Reference height for the base font size

                                          // Use the smaller of the width or height scaling factor to determine the font size
                                          const widthScale =
                                            pageWidth / referenceWidth;
                                          const heightScale =
                                            pageHeight / referenceHeight;
                                          const scaleFactor = Math.max(
                                            widthScale,
                                            heightScale
                                          ); // Keep proportions consistent

                                          // Set the initial font size based on the scale factor
                                          const initialFontSize = Math.round(
                                            baseFontSize * scaleFactor
                                          );
                                          setPos({
                                            ...pos,
                                            [data]: {
                                              text: (
                                                tableData[0][data] || data
                                              ).replaceAll(/;/g, ","),
                                              page: currentPage,
                                              x:
                                                // pagesUrl[currentPage - 1]
                                                //   ?.width / 2,
                                                1,
                                              y:
                                                // pagesUrl[currentPage - 1]
                                                //   ?.height / 2,
                                                1,
                                              color: [0, 0, 0],
                                              size: initialFontSize,
                                              fontStyle: "TimesNewRoman",
                                              fontFile: TimesNewRoman,
                                            },
                                          });
                                        }}
                                      >
                                        <Plus size={15} />
                                        <UncontrolledTooltip
                                          placement="bottom"
                                          target="addVariable"
                                        >
                                          Add
                                        </UncontrolledTooltip>
                                      </Button>
                                    )}
                                    {pos[data] && (
                                      <>
                                        <Button
                                          id="removeVariable"
                                          className="btn-icon"
                                          color="primary"
                                          size="sm"
                                          outline
                                          style={{
                                            margin: "5px",
                                            padding: "3px",
                                          }}
                                          onClick={async () => {
                                            setPos((current) => {
                                              const copy = { ...current };
                                              delete copy[data];
                                              return copy;
                                            });
                                          }}
                                        >
                                          <Trash2 size={15} />
                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target="removeVariable"
                                          >
                                            Remove
                                          </UncontrolledTooltip>
                                        </Button>
                                        <ButtonDropdown
                                          className="dropdown-icon-wrapper"
                                          isOpen={
                                            optionsVisible &&
                                            optionsVisible?.visible === true &&
                                            optionsVisible?.key === data
                                          }
                                          toggle={() =>
                                            toggleOptionVisible(data)
                                          }
                                          style={{
                                            width: "max-content !important",
                                            minWidth: "max-content !important",
                                          }}
                                        >
                                          <DropdownToggle
                                            id="edit"
                                            color="primary"
                                            caret
                                            outline
                                            className="btn-icon btn-sm d-flex align-items-center"
                                            style={{
                                              margin: "5px",
                                              padding: "3px",
                                            }}
                                          >
                                            <Edit size={16} />
                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target="edit"
                                            >
                                              Edit
                                            </UncontrolledTooltip>
                                          </DropdownToggle>
                                          <DropdownMenu
                                            end
                                            style={{
                                              width: "max-content !important",
                                              minWidth: "",
                                            }}
                                          >
                                            <div
                                              className="px-2 py-2"
                                              style={{ width: "max-content" }}
                                            >
                                              <div className="mb-2">
                                                <Label
                                                  className="form-label"
                                                  for="font"
                                                >
                                                  Select Font
                                                </Label>
                                                <select
                                                  className="form-select add_fontstyle"
                                                  id="font"
                                                  aria-label="Select Font"
                                                  value={
                                                    fontStyleOptions.filter(
                                                      (option) =>
                                                        option.value ===
                                                        pos[`${data}`]
                                                          ?.fontStyle
                                                    )[0]?.value
                                                  }
                                                  label={
                                                    fontStyleOptions.filter(
                                                      (option) =>
                                                        option.value ===
                                                        pos[`${data}`]
                                                          ?.fontStyle
                                                    )[0]?.label
                                                  }
                                                  style={
                                                    fontStyleOptions.filter(
                                                      (option) =>
                                                        option.value ===
                                                        pos[`${data}`]
                                                          ?.fontStyle
                                                    )[0]?.style
                                                  }
                                                  onChange={async (e) => {
                                                    setPos({
                                                      ...pos,
                                                      [data]: {
                                                        ...pos[`${data}`],
                                                        fontStyle:
                                                          e.target.value,
                                                        fontFile:
                                                          fontStyleOptions.filter(
                                                            (option) =>
                                                              option.value ===
                                                              e.target?.value
                                                          )[0]?.file,
                                                      },
                                                    });
                                                  }}
                                                >
                                                  <option
                                                    defaultValue="Select Font"
                                                    disabled
                                                  >
                                                    Select Font :
                                                  </option>
                                                  {fontStyleOptions.map(
                                                    (option) => (
                                                      <option
                                                        value={option.value}
                                                        style={option.style}
                                                      >
                                                        {option.label}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                              <div className="my-3 d-flex align-items-center color-picker-wrapper">
                                                <Label
                                                  className="form-label m-0"
                                                  for="color"
                                                >
                                                  Select Color :
                                                </Label>
                                                <input
                                                  className="p-0 border-0 ms-2"
                                                  type="color"
                                                  id="color"
                                                  name="color"
                                                  value={
                                                    pos[`${data}`]?.color
                                                      ? rgbToHex(
                                                          pos[`${data}`]?.color
                                                        )
                                                      : [0, 0, 0]
                                                  }
                                                  onChange={async (e) => {
                                                    let hexValue =
                                                      e?.target?.value;
                                                    let rgbValue = [
                                                      ("0x" +
                                                        hexValue[1] +
                                                        hexValue[2]) |
                                                        0,
                                                      ("0x" +
                                                        hexValue[3] +
                                                        hexValue[4]) |
                                                        0,
                                                      ("0x" +
                                                        hexValue[5] +
                                                        hexValue[6]) |
                                                        0,
                                                    ];
                                                    setPos({
                                                      ...pos,
                                                      [data]: {
                                                        ...pos[`${data}`],
                                                        color: rgbValue,
                                                      },
                                                    });
                                                  }}
                                                />
                                              </div>
                                              <div className="mb-2">
                                                <Label
                                                  className="form-label"
                                                  for="size"
                                                >
                                                  Font Size :
                                                </Label>
                                                <Input
                                                  className=""
                                                  placeholder="Enter Font Size"
                                                  type="number"
                                                  id="size"
                                                  max={10000}
                                                  min={5}
                                                  onWheel={(e) =>
                                                    e.currentTarget.blur()
                                                  }
                                                  onFocus={(e) =>
                                                    e.target.select()
                                                  }
                                                  value={pos[`${data}`]?.size}
                                                  onChange={(e) => {
                                                    if (
                                                      parseInt(
                                                        e.target.value || 0
                                                      ) >= 0
                                                    ) {
                                                      setPos({
                                                        ...pos,
                                                        [data]: {
                                                          ...pos[`${data}`],
                                                          size: parseInt(
                                                            e?.target?.value
                                                              .replace(
                                                                /[^0-9]/g,
                                                                ""
                                                              )
                                                              .replaceAll(
                                                                RegExp(
                                                                  "^0+(?!$)",
                                                                  "g"
                                                                ),
                                                                ""
                                                              )
                                                          ),
                                                        },
                                                      });
                                                    }
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </DropdownMenu>
                                        </ButtonDropdown>
                                      </>
                                    )}
                                  </Col>
                                </div>
                              </>
                            )
                          );
                        })}
                    </div>
                  </div>
                </Col>
                <Col md="9">
                  <div>
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      <Button outline color="primary" onClick={handleZoomOut}>
                        <ZoomOut />
                      </Button>
                      <Button outline color="primary" onClick={handleZoomIn}>
                        <ZoomIn />
                      </Button>
                    </div>
                    <div className="d-flex align-items-center justify-content-center pt-4">
                      {currentPage > 1 && (
                        <div>
                          <ChevronLeft
                            color="#624bff"
                            size={35}
                            onClick={() => {
                              setCurrentPage(currentPage - 1);
                            }}
                          />
                        </div>
                      )}
                      {file && (
                        <div
                          ref={containerRef}
                          className="align-items-center border"
                          style={{
                            overflow: "auto",
                            // border:
                            // maxHeight: 500,
                            // maxWidth: 1000,
                          }}
                        >
                          <div className="position-relative w-100 h-100">
                            <Stage
                              id="stage"
                              ref={stageRef}
                              // width={pagesUrl[currentPage - 1]?.width}
                              // height={pagesUrl[currentPage - 1]?.height}
                              width={stageSize.width} // Dynamic width based on container
                              height={stageSize.height} // Dynamic height based on container
                              scaleX={zoomLevel}
                              scaleY={zoomLevel}
                              onWheel={handleWheel}
                              draggable
                            >
                              <Layer>
                                <URLImage
                                  image={pagesUrl[currentPage - 1]?.base64}
                                />
                                {Object.keys(pos).map((key) => {
                                  if (pos[key]?.page === currentPage) {
                                    return (
                                      <Text
                                        className="position-absolute"
                                        text={tableData[0][key]}
                                        x={pos[key]?.x}
                                        y={pos[key]?.y}
                                        draggable
                                        onDragEnd={(e) => {
                                          handleDragEnd(e, key);
                                        }}
                                        onClick={() => setSelectedTextKey(key)}
                                        fill={`rgb(${pos[key]?.color[0]} ${pos[key]?.color[1]} ${pos[key]?.color[2]})`}
                                        fontSize={pos[key]?.size}
                                        fontFamily={pos[key]?.fontStyle}
                                        font
                                      />
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                              </Layer>
                            </Stage>
                          </div>
                          {totalPages > 1 && (
                            <div
                              align="center"
                              style={{ color: "#624bff", background: "white" }}
                            >
                              {currentPage} of {totalPages}
                            </div>
                          )}
                        </div>
                      )}

                      {totalPages === 1 ||
                        (currentPage < totalPages && (
                          <div>
                            <ChevronRight
                              size={35}
                              color="#624bff"
                              onClick={() => {
                                setCurrentPage(currentPage + 1);
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Card>
            <CardHeader className="header-table mb-75">
              <div className=" fw-bold text-light">Message Preview</div>
            </CardHeader>
            <CardBody>
              <pre>{messagePreview}</pre>
            </CardBody>
          </Card>
        )} */}

        <Modal isOpen={isTestOpen} size="sm" className="modal-dialog">
          <ModalHeader toggle={toggleTestModal} className="modal_header ">
            <h5 className="mb-0">Test Message</h5>
          </ModalHeader>
          <ModalBody>
            <Label>Enter Mobile Number</Label>
            <Input
              name="mobile"
              value={testMobile}
              onChange={(e) => {
                setTestMobile(e.target.value);
              }}
              type="number"
            />
          </ModalBody>
          <ModalFooter className="d-flex justify-content-between align-items-baseline">
            <div>
              <Button
                color="primary"
                className="mx-25"
                onClick={(e) => {
                  sendTestMessage();
                }}
                disabled={isSubmitting}
              >
                Ok
              </Button>
              <Button
                className="mx-25"
                color="secondary"
                onClick={() => {
                  toggleTestModal();
                }}
              >
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={modalSchedule}
          toggle={toggleSchedule}
          className="modal-dialog-centered"
          centered
        >
          <ModalHeader
            toggle={toggleSchedule}
            className="modal_header border-0"
          >
            Schedule Send
          </ModalHeader>
          <ModalBody className="modal_body py-3">
            <div className="position-relative floating-label-content">
              <Input
                type="datetime-local"
                id="schedule-time"
                onChange={inputdata}
                value={formData?.schedule}
                name="schedule"
                min={moment().format("YYYY-MM-DD HH:mm")}
                max={moment(expiry_date).format("YYYY-MM-DDTHH:mm")}
                className="w-100 bg-white fs-16 floating-textarea manual_input"
              />
              <Label className="bg-white position-absolute floating-label">
                Select Date
              </Label>
              <p className="text-danger">{formError?.schedule}</p>
            </div>
          </ModalBody>
          <ModalFooter className="border-0 d-inline pt-5 modal_footer">
            <div className="text-center">
              <Button
                color="secondary"
                onClick={() => {
                  toggleSchedule();
                }}
                className="mx-25 me-4"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  toggleSchedule();
                }}
                className="mx-25"
                disabled={formError?.schedule ? true : false}
              >
                Ok
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </>
    </Fragment>
  );
};

export default EditPdf;
