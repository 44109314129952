import React, { useCallback, useEffect, useRef, useState } from "react";
// import Input from "../../../../components/input/index";
import { Image } from "react-bootstrap";
import CommomButton from ".././../../../components/common-button/index";
import { Input, } from "reactstrap";
import { pdfjs } from "react-pdf";
import { PDFDocument } from "pdf-lib";
// import useImage from "use-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Zoom, Pagination, EffectFade, Navigation } from "swiper/modules";
import {
  Upload04Icon,
  ArrowRightIcon,
  ArrowLeftIcon,
} from "../../../../icons/icons";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

// const URLImage = ({ image }) => {
//   const [img] = useImage(image);
//   return <Image image={img} x={0} y={0} className="" />;
// };

const InsertPdf = ({
  stepper,
  totalPages,
  setTotalPages,
  fileType,
  setFileType,
  file,
  setFile,
  pagesUrl,
  setPagesUrl,
  inputdata,
  formData,
  setFileName,
  fileName,
  selectedImage,
  setSelectedImage,
  originalFile,
  setOriginalFile,
  campaingDetails,
  fileUrl,
  setFileUrl,
  validateStep,
  step,
  setSnackbarMessage,
  setSnackbarVariant,
  setShowSnackbar,
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  // const stageRef = useRef(null);

  // const [currentPage, setCurrentPage] = useState(1);
  const selectableMaxFileSize = 1024 * 1024 * 5;
  const [loading, setLoading] = useState(false);
  console.log("Loading ::::::::::::", loading);

  const generateImageToPdf = useCallback(
    async (file, url) => {
      const imageBytes = await fetch(
        file ? URL.createObjectURL(file) : url
      ).then((res) => res.arrayBuffer());
      setPagesUrl(
        new File([imageBytes], "converted-file.png", {
          type: "image/png",
        })
      );
      const pdfDoc = await PDFDocument.create();
      let image;
      if (file) {
        if (file.type === "image/png") {
          image = await pdfDoc.embedPng(imageBytes);
        } else {
          image = await pdfDoc.embedJpg(imageBytes);
        }
      } else if (url) {
        // If a URL is provided, you may need to check for file extension or other means to determine if it's PNG or JPG
        const fileExtension = url.split(".").pop().toLowerCase();

        console.log("File Extention : ", fileExtension);
        if (fileExtension === "png") {
          image = await pdfDoc.embedPng(imageBytes);
        } else if (fileExtension === "jpg" || fileExtension === "jpeg") {
          image = await pdfDoc.embedJpg(imageBytes);
        } else {
          throw new Error(
            "Unsupported URL file type. Only PNG and JPEG URLs are allowed."
          );
        }
      }

      const page = pdfDoc.addPage();
      page.setHeight(image.height);
      page.setWidth(image.width);
      page.drawImage(image, {
        x: page.getWidth() / 2 - image.width / 2,
        y: page.getHeight() / 2 - image.height / 2,
      });
      const pdfBytes = await pdfDoc.save();
      setFile(
        new File([pdfBytes], "converted-file.pdf", {
          type: "application/pdf;charset=utf-8",
        })
      );
    },
    [setFile, setPagesUrl]
  );

  useEffect(() => {
    const loadFileFromUrl = async (url, type) => {
      try {
        if (type === "application/pdf") {
          const loadingTask = pdfjs.getDocument(url);
          const pdf = await loadingTask.promise;
          const pages = pdf.numPages;

          console.log("PAGES : ", pages);
          setTotalPages(pages);
          let pagesData = [];
          for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            const viewport = page.getViewport({ scale: 1 });
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            await page.render(renderContext).promise;
            const dataURL = canvas.toDataURL("image/png");
            pagesData.push({
              base64: dataURL,
              height: viewport.height,
              width: viewport.width,
            });
          }
          const response = await fetch(url);
          const blob = await response.blob();
          const file = new File([blob], "sample.pdf", {
            type: "application/pdf",
          });

          // Set the actual File object
          setFile(file);
          setPagesUrl(pagesData);
          setLoading(false);
        } else if (type.startsWith("image")) {
          console.log("Image");
          setSelectedImage(url);
          setOriginalFile(url);
          generateImageToPdf("", url);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error Fetching Pdf. ", error);
        setLoading(false);
      }
    };

    if (fileUrl && campaingDetails.status === "drafted") {
      setLoading(true)
      const newfileUrl = `${process.env.REACT_APP_API_URL}/storage/${fileUrl}`;
      console.log("loadFileFromUrl : ");
      loadFileFromUrl(newfileUrl, fileType);
      // setLoading(false);
    }
  }, [
    fileType,
    setPagesUrl,
    setTotalPages,
    setSelectedImage,
    setFile,
    campaingDetails.status,
    fileUrl,
    selectedImage,
    generateImageToPdf,
    setOriginalFile,
  ]);

  useEffect(() => {
    const pdfToCanvas = async (filedata) => {
      setLoading(true);
      const loadingTask = pdfjs.getDocument(
        window.URL.createObjectURL(filedata)
      );
      const pdf = await loadingTask.promise;
      const pages = pdf.numPages;
      setTotalPages(pages);
      let pagesData = [];
      for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const viewport = page.getViewport({ scale: 1 });
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        await page.render(renderContext).promise;
        const dataURL = canvas.toDataURL("image/png");
        pagesData.push({
          base64: dataURL,
          height: viewport.height,
          width: viewport.width,
        });
      }

      console.log("Page data : ", pagesData);
      setPagesUrl(pagesData);
      setLoading(false);
    };
    if (file) {
      pdfToCanvas(file);
      console.log("pdfToCanvas :::::::");
    } 
    // else {
    //   setLoading(false);
    //   console.log("pdfToCanvas Else :::::::");
    // }
  }, [file, setPagesUrl, setTotalPages]);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (file) => {
    if (file.size > selectableMaxFileSize) {
      if (!file) {
        setFile("");
        setTotalPages(0);
      }
      // Show error message here
      setSnackbarMessage("File Size is Not more Than 5mb)");
      setSnackbarVariant("danger");
      setShowSnackbar(true);
    } else {
      setFileUrl("");
      if (file.type === "application/pdf") {
        setFile(file);
        setOriginalFile(file);
        setFileType(file.type);
        setFileName(file.name);
      } else if (
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg"
      ) {
        console.log("File . type :: ", file.type);
        setSelectedImage(URL.createObjectURL(file));
        setFileName(file.name);
        setOriginalFile(file);
        generateImageToPdf(file, "");
        setFileType(file.type);
      } else {
        // Show error message here
        if (!file) {
          setFile("");
          setTotalPages(0);
        }
        setSnackbarMessage("Upload Valid Document(PDF or Image)");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
      }
    }

    fileInputRef.current.value = "";
  };

  // const resetInput = () => {
  //   setFile("");
  //   setTotalPages(0);
  //   setFileType("");
  //   setFileName("");
  //   setSelectedImage(null);
  //   setOriginalFile("");
  // };

  const prevRef = React.useRef(null);
  const nextRef = React.useRef(null);

  return (
    <>
      <div className="bg-white rounded-2 overflow-hidden common-shadow p-4 mb-4">
        <div className="import-file-content">
          <div className=" d-flex align-items-center justify-content-between gap-4 import-file-header mb-4">
            <div>
              <h5 className="fw-medium mb-1">Select PDF or Image File</h5>
              <p>Select what method for import contacts</p>
            </div>

            <div className={file ? "d-block" : "d-none"}>
              <CommomButton
                type="submit"
                icon={
                  <Upload04Icon
                    width={20}
                    height={20}
                    color="#ffffff"
                    strokeWidth="2.2"
                  />
                }
                onClick={handleButtonClick}
                className="btn-primary primary-shadow px-2"
                subClassName="justify-content-center"
              />
              <Input
                type="file"
                className="d-none"
                name="file_name"
                id="media-upload"
                innerRef={fileInputRef}
                onChange={async (e) => {
                  handleFileChange(e.target.files[0]);
                }}
              />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center gap-4">
            {loading || file ? (
              <div
                ref={prevRef}
                className={` mb-3 rounded-circle d-flex align-items-center justify-content-center slider-icon`}
              >
                <ArrowLeftIcon
                  width={20}
                  height={20}
                  color="#6d6b77"
                  strokeWidth="2"
                />
              </div>
            ) : null}

            {loading || file ? (
              <Swiper
                pagination={{
                  type: "fraction",
                }}
                navigation={{
                  prevEl: prevRef.current,
                  nextEl: nextRef.current,
                }}
                onBeforeInit={(swiper) => {
                  swiper.params.navigation.prevEl = prevRef.current;
                  swiper.params.navigation.nextEl = nextRef.current;
                }}
                zoom={true}
                effect={"fade"}
                modules={[Pagination, Zoom, EffectFade, Navigation]}
                className="mySwiper  mx-0 import-file-preview"
              >
                {pagesUrl.map((item, i) => {
                  return (
                    <SwiperSlide
                      key={i}
                      className="border rounded-1 overflow-hidden"
                    >
                      <div className="swiper-zoom-container ">
                        <Image
                          src={item.base64}
                          className=" img-fluid object-fit-cover"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : (
              <div
                className={`upload-contnt d-flex align-items-center justify-content-center cursor-pointer  rounded-2 ${
                  loading || file ? null : "w-100"
                }`}
                onClick={handleButtonClick}
              >
                <div>
                  <span className="rounded-2 d-flex align-items-center justify-content-center mx-auto upload-icon">
                    <Upload04Icon
                      width={22}
                      height={22}
                      color="#6d6b77"
                      strokeWidth="2"
                    />
                  </span>
                  <h5 className="fw-medium pt-2">Upload pdf or image file</h5>
                </div>
              </div>
            )}
            {loading || file ? (
              <div
                ref={nextRef}
                className={` mb-3 rounded-circle d-flex align-items-center justify-content-center slider-icon`}
              >
                <ArrowRightIcon
                  width={20}
                  height={20}
                  color="#6d6b77"
                  strokeWidth="2"
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {/* <Row>
        <Col xl="12" className="mb-25 ">
          <div className="mb-1">
            {loading || file ? (
              <Row className="pt-4 align-items-start justify-content-center">
                <Col lg="3" sm="3" xs="4">
                  <div className="contain_image h-100">
                    {loading ? (
                      <div className="loading-container">
                        <div className="loading-progress"></div>
                      </div>
                    ) : file ? (
                      <div className="text-start loading-container">
                        {console.log("File Type ; ", fileType)}
                        {fileType === "application/pdf" ? (
                          <img
                            src={pagesUrl[currentPage - 1]?.base64}
                            alt="thumbnail"
                            width={42}
                            height={42}
                          />
                        ) : (
                          <img
                            src={selectedImage}
                            alt="thumbnail1"
                            width={42}
                            height={42}
                          />
                        )}
                      </div>
                    ) : null}
                    <div className="d-flex justify-content-between mt-2 mx-25">
                      <p>{fileName}</p>
                      <img
                        src="/images/image-folder-icon/deletefile-icon.svg"
                        alt="delete-file"
                        width={20}
                        height={20}
                        // onClick={resetInput}
                        className="curser-pointer"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg="9" sm="9" xs="8" className="">
                  <div className="d-flex align-items-center gap-1 set-pdf">
                    {currentPage > 1 && (
                      <div>
                        <ChevronLeft
                          color="#624bff"
                          size={35}
                          onClick={() => {
                            setCurrentPage(currentPage - 1);
                          }}
                        />
                      </div>
                    )}
                    <div className="d-flex align-items-center justify-content-center uploading_image">
                      <div className=" w-100 h-100">
                        {loading ? (
                          <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            <div className="loading-container">
                              <div className="loading-progress"></div>
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            {file && (
                              <div
                                className="w-100 h-100 custom-pdfset"
                                style={{ overflow: "auto" }}
                              >
                                <Stage
                                  id="stage"
                                  ref={stageRef}
                                  width={pagesUrl[currentPage - 1]?.width}
                                  height={pagesUrl[currentPage - 1]?.height}
                                >
                                  <Layer>
                                    <URLImage
                                      image={pagesUrl[currentPage - 1]?.base64}
                                    />
                                  </Layer>
                                </Stage>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {totalPages === 1 ||
                      (currentPage < totalPages && (
                        <div>
                          <ChevronRight
                            size={35}
                            color="#624bff"
                            onClick={() => {
                              setCurrentPage(currentPage + 1);
                            }}
                          />
                        </div>
                      ))}
                  </div>
                  <div className="mt-3">
                    {totalPages > 1 && (
                      <div
                        align="center"
                        style={{
                          color: "#624bff",
                          background: "white",
                        }}
                      >
                        Page {currentPage} of {totalPages}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            ) : (
              <Row className="pt-4 align-items-center justify-content-center">
                <Col lg="9" sm="9" xs="8">
                  <div
                    className="d-flex align-items-center justify-content-center uploading_image"
                    onClick={handleButtonClick}
                    style={{ cursor: "pointer" }}
                  >
                    <div className=" w-100 h-100">
                      <div className="d-flex flex-column gap-3 align-items-center justify-content-center h-100 w-100">
                        <img
                          src="/images/image-folder-icon/upload-doc-icon.svg"
                          alt="uploading"
                          className="mx-auto"
                          fill
                        />
                        <h3 className="text-muted">Upload Document</h3>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row> */}
    </>
  );
};

export default InsertPdf;
