import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { Cancel01Icon } from "../../icons/icons";

export default function Alerts({ key, title, className }) {
  const [show, setShow] = useState(true);
  return (
    <Alert
      key={key}
      show={show}
      variant="none"
      className={`mb-0 custom-alert  ${className}`}
    >
      <div className="d-flex align-items-center justify-content-between gap-5">
        <div className="fw-normala">{title}</div>
        <span onClick={() => setShow(false)} className="cursor-pointer">
          <Cancel01Icon
            width={16}
            height={16}
            color="#000000"
            strokeWidth="2"
          />
        </span>
      </div>
    </Alert>
  );
}
