import React, { useEffect, useState, useCallback } from "react";
import Input from "../../../components/input/index";
import CommomButton from "../../../components/common-button/index";
import StatusChip from "../../../components/status-chip/index";
import CommonPagination from "../../../components/common-pagination/index"
import NavTabs from "../../../components/nav-tabs/index";
import "./rechargeHistory.css";
import {
  // Col,
  Container,
  // Row,
  // ProgressBar,
  Table,
  // Badge,
  // Tabs,
  // Tab,
  // Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletDetails } from "../../../store/walletSlice";
// import PaginationComponent from "../pagination/pagination";
import { createSelector } from "reselect";
// import { ArrowUp, ArrowDown } from "react-feather";
import CommonLayout from "../../layouts/master/index";
import { useAuth } from "../../../context/AuthContext";
import { useLoader } from "../../../context/LoaderContext";
import Credit from "./Credit";
import moment from "moment";

import { Add01Icon } from "../../../icons/icons";
import { RechargeHistoryTabs } from "../../../constants/pages/recharge-history/index"

export default function RechargeHistory() {
  const { setLoading } = useLoader();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 10;
  // const [sortKey, setSortKey] = useState("id");
  // const [sortOrder, setSortOrder] = useState("desc");
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState(null);

  // const walletCreditDetail = useSelector((state) => state.wallet.myCredit);

  const selectWalletState = (state) => state.wallet.walletDetails;

  const selectWalletData = createSelector(
    [selectWalletState],
    (walletDetails) => {
      return {
        total: walletDetails.total,
        credits: walletDetails.data,
      };
    }
  );

  const { total, credits } = useSelector(selectWalletData);

  const getWalletList = useCallback(() => {
    setLoading(true);
    Promise.all([
      // dispatch(fetchMyCredit(user.id)),
      dispatch(
        fetchWalletDetails({
          id: user.id,
          offset: currentPage,
          limit,
          search: "",
          // sort_by_key: sortKey,
          // sort_by_value: sortOrder,
          status: activeTab,
        })
      ),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    user,
    currentPage,
    limit,
    // sortKey,
    // sortOrder,
    setLoading,
    activeTab,
  ]);

  useEffect(() => {
    getWalletList();
  }, [getWalletList]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
    >
      {children}
    </Link>
  ));

  CustomToggle.displayName = "CustomToggle";

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  // const handleSort = (key) => {
  //   const isAsc = sortKey === key && sortOrder === "asc";
  //   setSortKey(key);
  //   setSortOrder(isAsc ? "desc" : "asc");
  // };

  // const renderSortIcons = (key) => {
  //   const isActive = sortKey === key;
  //   return (
  //     <>
  //       <ArrowUp
  //         size={16}
  //         onClick={() => handleSort(key, "asc")}
  //         style={{
  //           cursor: "pointer",
  //           color: isActive && sortOrder === "asc" ? "black" : "#ccc",
  //         }}
  //       />
  //       <ArrowDown
  //         size={16}
  //         onClick={() => handleSort(key, "desc")}
  //         style={{
  //           cursor: "pointer",
  //           color: isActive && sortOrder === "desc" ? "black" : "#ccc",
  //         }}
  //       />
  //     </>
  //   );
  // };

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   const day = date.getDate().toString().padStart(2, "0");
  //   const month = (date.getMonth() + 1).toString().padStart(2, "0");
  //   const year = date.getFullYear();

  //   return `${day}/${month}/${year}`;
  // };

  // const usedCredits = (available_credits, total_credits) => {
  //   if (
  //     !Number.isFinite(available_credits) ||
  //     !Number.isFinite(total_credits) ||
  //     total_credits === 0
  //   ) {
  //     return 0;
  //   }
  //   return Math.round(
  //     ((total_credits - available_credits) / total_credits) * 100
  //   );
  // };

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    getWalletList();
  };

  const handleTabSelect = (key) => {
    if (key === "") {
      setActiveTab(null);
    } else {
      setActiveTab(key);
    }

    setCurrentPage(0);
  };

  return (
    <>
      <CommonLayout>
        <Container fluid className="px-0">
          {/* Old Recharge History Code */}
          {/* <Row>
            <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
              <div className="position-relative overflow-hidden border-radius-8 tablecontainer">
                <div className="d-flex align-items-start justify-content-between mb-2">
                  <div className="position-relative overflow-hidden rounded-2 bg-white table-tabs mb-2">
                    <Tabs
                      defaultActiveKey=""
                      className="pb-0 table-tabs-items"
                      id="uncontrolled-tab-example"
                      onSelect={handleTabSelect}
                    >
                      <Tab
                        eventKey=""
                        title={<p className="mb-0">All</p>}
                      ></Tab>
                      <Tab
                        eventKey="placed"
                        title={<p className="mb-0">Requested</p>}
                      ></Tab>
                      <Tab
                        eventKey="completed"
                        title={<p className="mb-0">Completed</p>}
                      ></Tab>
                      <Tab
                        eventKey="rejected"
                        title={<p className="mb-0">Rejected</p>}
                      ></Tab>
                    </Tabs>
                  </div>
                  <Button variant="primary" onClick={() => handleShow()}>
                    Request Credits
                  </Button>
                </div>
                <div className="table-responsive rounded-2 custom-tbl">
                  <table className="text-nowrap text-start w-100">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              Purchase Date {renderSortIcons("created_at")}
                            </span>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Description</span>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Credit Rate</span>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Credits</span>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Total Price</span>
                          </div>
                        </th>
                        <th scope="col">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>Status</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="credit_data">
                      {credits?.length > 0 ? (
                        credits.map((credit, i) => (
                          <tr key={i} className="data_row">
                            <td>
                              {moment(credit.created_at).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </td>
                            <td>{credit?.description || "-"}</td>
                            <td>₹ {credit.credit_rate}</td>
                            <td>{credit.credits}</td>
                            <td>₹ {credit.price}</td>
                            <td>
                              {credit.status === "completed" ? (
                                <Badge pill bg="success">
                                  Completed
                                </Badge>
                              ) : credit.status === "rejected" ? (
                                <Badge pill bg="danger">
                                  Rejected
                                </Badge>
                              ) : (
                                <Badge pill bg="primary">
                                  Requested
                                </Badge>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7" className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {total > limit && (
                  <PaginationComponent
                    totalItems={total}
                    itemsPerPage={limit}
                    currentPage={currentPage + 1}
                    onPageChange={handlePageChange}
                  />
                )}
              </div>
            </Col>
          </Row> */}

          {/* New Recharge History Code */}
          <div className="mb-4">
            <NavTabs
              defaultActiveKey=""
              id="uncontrolled-tab-example"
              onSelect={handleTabSelect}
              tabsData={RechargeHistoryTabs}
            />
          </div>
          <div className="bg-white rounded-2 overflow-hidden common-shadow">

            <div className="p-4 d-flex align-items-center justify-content-between gap-4">
              <Input
                type="search"
                // value={searchTerm}
                placeholder="Search Recharge History"
                // onChange={(e) => {
                //   setSearchTerm(e.target.value);
                //   setCurrentPage(0);
                // }}
                className="shadow-none base-search"
              />

              <CommomButton
                onClick={() => handleShow()}
                icon={
                  <Add01Icon
                    width={16}
                    height={16}
                    color="#ffffff"
                    strokeWidth="2"
                  />
                }
                title="Request Credits"
                className="btn-primary common-one-shadow"
              />
            </div>

            <Table responsive className="common-table">
              <thead>
                <tr>
                  <th className="sorting">
                    <p>Purchase Date</p>
                  </th>
                  <th>
                    <p>Description</p>
                  </th>
                  <th>
                    <p>Credit Rate</p>
                  </th>
                  <th>
                    <p>Credits</p>
                  </th>
                  <th>
                    <p>Total Price</p>
                  </th>
                  <th>
                    <p>Status</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  credits?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td> {moment(item.created_at).format(
                          "DD/MM/YYYY hh:mm A"
                        )}</td>
                        <td>{item.description || '-'}</td>
                        <td>₹{item.credit_rate}</td>
                        <td>{item.credits}</td>
                        <td>{item.price}</td>
                        <td>
                          {item.status === "completed" ? (
                            <StatusChip
                              bg="chip-green"
                              title="Completed"
                              className="text-capitalize"
                            />
                          ) : item.status === "rejected" ? (
                            <StatusChip
                              bg="chip-red"
                              title="Rejected"
                              className="text-capitalize"
                            />
                          ) : (
                            <StatusChip
                              bg="chip-ornage"
                              title="Requested"
                              className="text-capitalize"
                            />
                          )}
                        </td>
                      </tr>
                    )
                  })
                }

              </tbody>
            </Table>

            <div className="p-3 mx-1 d-flex flex-wrap align-items-center justify-content-between gap-4">
              <p className="custom-table-infotext ">Displaying 1 to 10 of {total} Organizations</p>
              <div>
                {total > limit && (
                  <CommonPagination
                    totalItems={total}
                    itemsPerPage={limit}
                    currentPage={currentPage + 1}
                    onPageChange={handlePageChange}
                  />
                )}
              </div>
            </div>
          </div>

        </Container>
      </CommonLayout>

      {/* <RequestModal show={modallist} handleClose={handleCloseModal} /> */}
      <Credit
        show={showModal}
        handleClose={handleClose}
      // limit={limit}
      // sortKey={sortKey}
      // sortOrder={sortOrder}
      // activeTab={activeTab}
      />
    </>
  );
}
