import React from "react";
import { Form } from "react-bootstrap";

export default function RadioButton({
  name,
  id,
  label,
  value,
  checked,
  onChange,
  className,
}) {
  return (
    <Form.Check
      type="radio"
      name={name}
      label={label}
      id={id}
      value={value}
      checked={checked}
      onChange={onChange}
      className={`shadow-none custom-radio-btn ${className}`}
    />
  );
}
