import {
  DashboardSquare02Icon,
  Clock05Icon,
  CropIcon,
  ReturnRequestIcon,
  UserListIcon,
} from "../../icons/icons";

export const SidebarContent = [
  {
    title: "Dashbaord",
    icon: (
      <DashboardSquare02Icon
        width={18}
        height={18}
        color="#444050"
        strokeWidth="2"
      />
    ),
    link: "/dashboard",
  },
  {
    title: "Customers",
    icon: (
      <UserListIcon width={18} height={18} color="#444050" strokeWidth="2" />
    ),
    link: "/customers",
  },
  {
    title: "Campaigns",
    icon: (
      <Clock05Icon width={18} height={18} color="#444050" strokeWidth="2" />
    ),
    link: "/all-campaigns",
  },
  {
    title: "Credits History",
    icon: <CropIcon width={18} height={18} color="#444050" strokeWidth="2" />,
    link: "/credits-history",
  },
  {
    title: "Recharge History",
    icon: (
      <ReturnRequestIcon
        width={18}
        height={18}
        color="#444050"
        strokeWidth="2"
      />
    ),
    link: "/recharge-history",
  },
];
