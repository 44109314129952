import React from 'react'
import { Form } from 'react-bootstrap';

export default function Range({ value, onChange, id, className, min, max }) {
    return (
        <Form.Range
            value={value}
            onChange={onChange}
            id={id}
            min={min}
            max={max}
            className={` h-auto custom-range ${className}`}
        />
    )
}
