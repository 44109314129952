import React, { useState, useEffect } from "react";
import CommonFormLabel from "../../../components/common-form-label";
import Input from "../../../components/input";
import ValidationFeedback from "../../../components/validation-feedback";
import CommomButton from "../../../components/common-button/index";
import { Container, Image, Form, Toast, ToastContainer } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../../store/authSlice";
import { loginSchema } from "../../../schema/validationSchemas";
import {
  ViewOffSlashIcon,
  EyeIcon,
  CheckmarkBadge04Icon,
} from "../../../icons/icons";
import { IconData } from "../../../constants/pages/auth/login";

// const LogIn = () => {
//   const [showToast, setShowToast] = useState(false);
//   const [toastMessage, setToastMessage] = useState('');
//   const [toastVariant, setToastVariant] = useState('success');
//   const dispatch = useDispatch();
//   const { status, error } = useSelector((state) => state.auth);
//   const navigate = useNavigate();

//   const handleSubmit = async (values, { setSubmitting }) => {
//     try {
//       const result = await dispatch(userLogin(values)).unwrap();
//       if (result && result.success) {
//         // console.log('Result : ', result);
//         setToastMessage(result.message || 'Login successful!');
//         setToastVariant('success');
//         navigate('/dashboard');
//       } else {
//         setToastMessage(result.message || 'Failed to login!');
//         setToastVariant('danger');
//         navigate('/');
//       }
//     } catch (err) {
//       const errorMessage = err?.message || 'Failed to login!';
//       setToastMessage(errorMessage);
//       setToastVariant('danger');
//       navigate('/');
//     } finally {
//       setShowToast(true);
//       setSubmitting(false);
//     }
//   };

//   useEffect(() => {
//     if (error) {
//       setToastMessage(error);
//       setToastVariant('danger');
//       setShowToast(true);
//     }

//     if (status === 'loading') {
//       setToastMessage('Logging in...');
//       setToastVariant('warning');
//       setShowToast(true);
//     }
//   }, [status, error]);

//   return (
//     <Row className="align-items-center justify-content-center g-0 min-vh-100">
//       <Col xxl={4} lg={6} md={8} xs={12} className="py-8 py-xl-0">
//         <Toast
//           onClose={() => setShowToast(false)}
//           show={showToast}
//           delay={5000}
//           autohide
//           bg={toastVariant}
//           style={{ position: 'fixed', top: 20, right: 20, zIndex: 1000 }}
//         >
//           <Toast.Header>
//             <strong className="me-auto">Notification</strong>
//           </Toast.Header>
//           <Toast.Body>{toastMessage}</Toast.Body>
//         </Toast>
//         <Card className="smooth-shadow-md">
//           <Card.Body className="p-6">
//             <div className="mb-4">
//               <Link to="/"><img src="/images/brand/logo/logo-primary.svg" className="mb-2" alt="" /></Link>
//               <p className="d-flex mb-6 ml-2">Please enter your user information.</p>
//             </div>
//             <Formik
//               initialValues={{ email: '', password: '' }}
//               validationSchema={loginSchema}
//               onSubmit={handleSubmit}
//             >
//               {({ errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <label htmlFor="email" className="d-flex form-label">Email or Username</label>
//                     <Field
//                       id="email"
//                       name="email"
//                       type="text"
//                       placeholder="Enter your email or username here"
//                       className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                     />
//                     {touched.email && errors.email ? (
//                       <div className="invalid-feedback">{errors.email}</div>
//                     ) : null}
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="password" className="d-flex form-label">Password</label>
//                     <Field
//                       id="password"
//                       name="password"
//                       type="password"
//                       placeholder="**************"
//                       className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                     />
//                     {touched.password && errors.password ? (
//                       <div className="invalid-feedback">{errors.password}</div>
//                     ) : null}
//                   </div>

//                   <div className="d-grid w-28 d-flex justify-content-center mt-5">
//                     <Button variant="primary" type="submit" disabled={isSubmitting}>Log In</Button>
//                   </div>
//                   <div className="d-grid w-28 d-flex justify-content-center mt-3">
//                     <Link to="/forgot-password" className='text-decoration-underline'>Forgot Password</Link>
//                   </div>
//                 </form>
//               )}
//             </Formik>
//           </Card.Body>
//         </Card>
//       </Col>
//     </Row>
//   );
// }

// export default LogIn;

export default function LogIn() {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("toast-success");
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const result = await dispatch(userLogin(values)).unwrap();
      if (result && result.success) {
        // console.log('Result : ', result);
        setToastMessage(result.message || "Login successful!");
        setToastVariant("toast-success");
        navigate("/dashboard");
      } else {
        setToastMessage(result.message || "Failed to login!");
        setToastVariant("toast-danger");
        navigate("/");
      }
    } catch (err) {
      const errorMessage = err?.message || "Failed to login!";
      setToastMessage(errorMessage);
      setToastVariant("toast-danger");
      navigate("/");
    } finally {
      setShowToast(true);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (error) {
      setToastMessage(error);
      setToastVariant("toast-danger");
      setShowToast(true);
    }

    if (status === "loading") {
      setToastMessage("Logging in...");
      setToastVariant("toast-warning");
      setShowToast(true);
    }
  }, [status, error]);
  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={5000}
          autohide
          bg={toastVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{toastMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Container fluid="xxl" className="mx-auto px-4">
        <div className="d-flex align-items-center justify-content-center py-4  login-content">
          <div className="position-relative w-100 py-4 login-sub-conetnt">
            <div className="bg-white rounded-2 p-5 common-shadow login-card">
              <h1 className="fs-5 d-flex align-content-center justify-content-center mb-4">
                <Link
                  to="https://invitewala.in/"
                  className=" text-decoration-none  d-inline-block"
                >
                  <Image
                    src="/assets/images/logo/logo-black.svg"
                    alt="Site-Logo"
                  />
                </Link>
              </h1>
              <div className="mb-4 welcome-content">
                <h4 className="mb-1 fw-medium">Welcome to Invitewala! 👋</h4>
                <p>Please sign-in to your account and start the adventure</p>
              </div>
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={loginSchema}
                onSubmit={handleSubmit}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <CommonFormLabel title="Email or Username" />
                      <Input
                        id="email"
                        name="email"
                        type="text"
                        placeholder="Enter your email or username"
                        onChange={handleChange}
                        className={`shadow-none ${
                          touched.email && errors.email ? "error" : "correct"
                        }`}
                      />
                      {touched.email && errors.email ? (
                        <ValidationFeedback title={errors.email} />
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <CommonFormLabel title="Password" />
                      <div className="position-relative">
                        <Input
                          id="password"
                          name="password"
                          type="password"
                          placeholder="············"
                          onChange={handleChange}
                          className={`shadow-none ${
                            touched.password && errors.password
                              ? "error"
                              : "correct"
                          }`}
                        />
                        <div className=" position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer">
                          {/* EyeSlash Icon */}
                          <ViewOffSlashIcon
                            width={20}
                            height={20}
                            color="#444050"
                            strokeWidth="1.5"
                            className={
                              touched.password && errors.password
                                ? "error"
                                : "correct"
                            }
                          />

                          {/* Eye Icon - [Abi Ye Icon Ko Hide Kiya He d-none Ka Class Lag raha he Esi Liye Hide He.] */}
                          <EyeIcon
                            width={20}
                            height={20}
                            color="#444050"
                            strokeWidth="1.5"
                            className={`d-none ${
                              touched.password && errors.password
                                ? "error"
                                : "correct"
                            }`}
                          />
                        </div>
                      </div>
                      {touched.password && errors.password ? (
                        <ValidationFeedback title={errors.password} />
                      ) : null}
                    </div>
                    <div className="d-flex align-items-center justify-content-end forget-link">
                      <Link
                        to="/forgot-password"
                        className="text-decoration-none "
                      >
                        Forgot Password?
                      </Link>
                    </div>
                    <CommomButton
                      title="Login"
                      type="submit"
                      disabled={isSubmitting}
                      className="w-100 btn-primary primary-shadow "
                    />
                    <div className="white-space-nowrap d-block overflow-hidden text-center my-4">
                      <div className=" position-relative d-inline-block px-3 divider-text">
                        or
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      {IconData.map((item, i) => {
                        return (
                          <Link
                            to={item.link}
                            key={i}
                            className={`rounded-circle d-flex align-items-center justify-content-center form-icon ${item.className}`}
                          >
                            {item.icon}
                          </Link>
                        );
                      })}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
