import React, { useCallback, useEffect, useState } from "react";
import BackPageTab from "../../../components/back-page-tab/index";
import CampaignsType from "./steps/CampaignsType";
import CommomButton from "../../../components/common-button/index";

import {
  Button,
  Toast,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ToastContainer,
} from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InsertCsv from "./steps/InsertCsv";
import InsertPdf from "./steps/InsertPdf";
import CommonLayout from "../../layouts/master/index";
import InsertMessage from "./steps/InsertMessage";
import EditPdf from "./steps/EditPdf";
import { useDispatch, useSelector } from "react-redux";
import {
  addCampaign,
  clearCampaignDetails,
  fetchCampaignById,
  updateEvent,
} from "../../../store/eventsSlice";
import ProgressBar from "./progressBar/ProgressBar";
import moment from "moment";
import { useLoader } from "../../../context/LoaderContext";
import { CheckmarkBadge04Icon } from "../../../icons/icons";
// import CampaignsType from "./steps/CampaignsType";
// import axios from "axios";

import {
  ArrowLeftIcon,
  ArrowRight02Icon,
  BlackHoleIcon,
  SmartPhone04Icon,
  FileUploadIcon,
  MailSend02Icon,
  MessageAdd01Icon,
  ArrowLeft02Icon,
  NoteEditIcon,
  SentIcon,
} from "../../../icons/icons";

const getSteps = (templateType) => {
  if (templateType === "text") {
    return [
      {
        id: "choose-campaigns-type",
        icon: (
          <BlackHoleIcon
            width={22}
            height={22}
            color="#444050"
            strokeWidth="1.8"
          />
        ),
        title: "Campaigns Type",
        subtitle: "Select what method for send campaigns",
        component: CampaignsType,
      },
      {
        id: "whatsapp-details",
        icon: (
          <SmartPhone04Icon
            width={22}
            height={22}
            color="#444050"
            strokeWidth="1.8"
          />
        ),
        title: "Import Numbers",
        subtitle: "Enter WhatsApp Numbers",
        component: InsertCsv,
      },
      {
        id: "message-details",
        icon: (
          <MessageAdd01Icon
            width={22}
            height={22}
            color="#444050"
            strokeWidth="1.8"
          />
        ),
        title: "Add Message",
        subtitle: "Add Message",
        component: InsertMessage,
      },
    ];
  } else {
    return [
      {
        id: "choose-campaigns-type",
        icon: (
          <BlackHoleIcon
            width={22}
            height={22}
            color="#444050"
            strokeWidth="1.8"
          />
        ),
        title: "Campaigns Type",
        subtitle: "Select what method for send campaigns",
        component: CampaignsType,
      },
      {
        id: "whatsapp-details",
        icon: (
          <SmartPhone04Icon
            width={22}
            height={22}
            color="#444050"
            strokeWidth="1.8"
          />
        ),
        title: "Import Numbers",
        subtitle: "Enter WhatsApp Numbers",
        component: InsertCsv,
      },
      {
        id: "import-file",
        icon: (
          <FileUploadIcon
            width={22}
            height={22}
            color="#444050"
            strokeWidth="1.8"
          />
        ),
        title: "Import File",
        subtitle: "Add PDF or Image",
        component: InsertPdf,
      },
      {
        id: "send-invitation",
        icon: (
          <MailSend02Icon
            width={22}
            height={22}
            color="#444050"
            strokeWidth="1.8"
          />
        ),
        title: "Send Invitation",
        subtitle: "Send Invitation",
        component: EditPdf,
      },
      {
        id: "message-details",
        icon: (
          <MessageAdd01Icon
            width={22}
            height={22}
            color="#444050"
            strokeWidth="1.8"
          />
        ),
        title: "Add Message",
        subtitle: "Add Message",
        component: InsertMessage,
      },
    ];
  }
};

const WizardStep = ({
  step,
  formData,
  setFormData,
  formError,
  setFormError,
  inputdata,
  setTotalPages,
  totalPages,
  setFileType,
  fileType,
  setFile,
  file,
  setPagesUrl,
  pagesUrl,
  messageData,
  setMessageData,
  tableData,
  setTableData,
  csvKeys,
  setCsvKeys,
  setPos,
  pos,
  validateStep,
  errors,
  manualText,
  setManualText,
  textAreaData,
  setTextAreaData,
  validation,
  currentStep,
  setFileName,
  fileName,
  setSelectedImage,
  selectedImage,
  setOriginalFile,
  originalFile,
  campaingDetails,
  isTestOpen,
  setIsTestOpen,
  handleTestClick,
  fileUrl,
  setFileUrl,
  templateType,
  setSnackbarMessage,
  setSnackbarVariant,
  setShowSnackbar,
  setInitialCampaignState,
  initialCampaignState,
  clientFromQuery,
}) => {
  const StepComponent = step.component;
  return (
    <div>
      <StepComponent
        formData={formData}
        setFormData={setFormData}
        formError={formError}
        setFormError={setFormError}
        inputdata={inputdata}
        setTotalPages={setTotalPages}
        totalPages={totalPages}
        setFileType={setFileType}
        fileType={fileType}
        setFile={setFile}
        file={file}
        setPagesUrl={setPagesUrl}
        pagesUrl={pagesUrl}
        messageData={messageData}
        setMessageData={setMessageData}
        tableData={tableData}
        setTableData={setTableData}
        csvKeys={csvKeys}
        setCsvKeys={setCsvKeys}
        setPos={setPos}
        pos={pos}
        validateStep={validateStep}
        errors={errors}
        manualText={manualText}
        setManualText={setManualText}
        textAreaData={textAreaData}
        setTextAreaData={setTextAreaData}
        validation={validation}
        currentStep={currentStep}
        setFileName={setFileName}
        fileName={fileName}
        setSelectedImage={setSelectedImage}
        selectedImage={selectedImage}
        setOriginalFile={setOriginalFile}
        originalFile={originalFile}
        campaingDetails={campaingDetails}
        isTestOpen={isTestOpen}
        setIsTestOpen={setIsTestOpen}
        handleTestClick={handleTestClick}
        fileUrl={fileUrl}
        setFileUrl={setFileUrl}
        templateType={templateType}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarVariant={setSnackbarVariant}
        setShowSnackbar={setShowSnackbar}
        setInitialCampaignState={setInitialCampaignState}
        initialCampaignState={initialCampaignState}
        clientFromQuery={clientFromQuery}
      />
    </div>
  );
};

const EventIndex = ({ case_type, initial_step }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {  campaignId: id } = useParams();
  const location = useLocation();
  const [clientFromQuery, setClientFromQuery] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");
  const [step, setStep] = useState(initial_step);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const client = queryParams.get("client");

    console.log("CLient ::::: ", client);

    if (client) {
      setInitialCampaignState((prevData) => ({
        ...prevData,
        customer: client, // Set client as the default selected customer
      }));
      setClientFromQuery(client); // Save the client from the query
    }
  }, [location.search]);

  useEffect(() => {
    return () => {
      // Clear campaign details when the component unmounts
      dispatch(clearCampaignDetails());
    };
  }, [dispatch]);

  console.log("Case Type :::::::::", case_type);

  const initialState = {
    schedule: "",
    countryCode: "",
    delaySecond: 5,
    delayFromSecond: 3,
    delayToSecond: 10,
  };

  const campaingDetails = useSelector((state) => state.events.campaingDetails);

  // console.log("cCampaign Data : ", campaingDetails);

  const [formData, setFormData] = useState(initialState);
  const [formError, setFormError] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const [fileType, setFileType] = useState("");
  const [messageData, setMessageData] = useState("");
  const [tableData, setTableData] = useState([]);
  const [csvKeys, setCsvKeys] = useState([]);
  const [file, setFile] = useState(null);
  const [pagesUrl, setPagesUrl] = useState([]);
  const [pos, setPos] = useState({});
  const [errors, setErrors] = useState({});
  const [manualText, setManualText] = useState("");
  const [textAreaData, setTextAreaData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [originalFile, setOriginalFile] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [showExitModal, setShowExitModal] = useState(false);
  const [initialCampaignState, setInitialCampaignState] = useState({
    name: "",
    sendOption: "invitation",
    customer: clientFromQuery || "",
  });

  const [templateType, setTemplateType] = useState("");

  const steps = getSteps(templateType);

  const inputdata = (e) => {
    console.log("Input Change  : ", e.target);
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormError({
      ...formError,
      [e.target.name]: validation({ [e.target.name]: e.target.value })[
        e.target.name
      ],
    });

    console.log("Error : ", formError);
  };
  const { setLoading } = useLoader();

  const getCampaignDetail = useCallback(() => {
    setLoading(true);
    dispatch(
      fetchCampaignById({
        // offset: currentPage,
        // limit,
        // search: searchTerm,
        id: id,
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, id, setLoading]);

  useEffect(() => {
    if (case_type === "update") {
      getCampaignDetail();
    }
  }, [step, case_type, getCampaignDetail]);

  const [isTestOpen, setIsTestOpen] = useState(false);

  const handleTestClick = async () => {
    // console.log("campaingDetails?.customer?.credits : ", tableData?.length);
    if (campaingDetails?.customer?.credits <= tableData?.length) {
      setSnackbarMessage("Not have enough credits.");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
      return;
    }
    setIsTestOpen(true);
  };

  useEffect(() => {
    if (
      campaingDetails?.status === "drafted" ||
      campaingDetails?.status === "pending"
    ) {
      // console.log("Campaign data : ", campaingDetails);
      setFormData({
        schedule: campaingDetails?.scheduler_date
          ? moment(campaingDetails?.scheduler_date).format("YYYY-MM-DDTHH:mm")
          : "",
        countryCode: campaingDetails?.country_code || "",
        delaySecond: parseInt(campaingDetails?.delay_second) || 5,
        delayFromSecond: parseInt(campaingDetails?.delay_second_from) || 3,
        delayToSecond: parseInt(campaingDetails?.delay_second_to) || 10,
      });

      setMessageData(campaingDetails?.message || "");

      const guests = campaingDetails?.client_guests?.map((guest) => {
        let guestData = {
          name: guest.name,
          number: guest.mobile_number,
        };
        if (guest?.variables && Object.keys(guest.variables).length > 0) {
          Object.keys(guest.variables).forEach((key) => {
            guestData[key] = guest.variables[key];
          });
        }

        return guestData;
      });

      setTableData(guests || []); // Assuming `client_guests` is the list of guests
      setPos(campaingDetails.variable_positions || {});
      setCsvKeys(campaingDetails.variables || []);
      setFileName(fileType === "application/pdf" ? "sample.pdf" : "sample.png");
      setFileUrl(originalFile ? "" : campaingDetails.template_url);
      const manualTextContent = campaingDetails?.client_guests
        ?.map((guest) => {
          const variables = guest?.variables
            ? Object.values(guest.variables).join(",")
            : "";
          return `${guest.name},${guest.mobile_number}${
            variables ? "," + variables : ""
          }`;
        })
        .join("\n");
      setManualText(manualTextContent);

      // Set textAreaData based on manualText
      setTextAreaData(manualTextContent?.split("\n"));

      console.log("Campauign Data ::::::::::::::::::::", campaingDetails);

      setInitialCampaignState({
        name: campaingDetails?.name,
        sendOption: "invitation",
        customer: campaingDetails?.client_event?.id,
      });
    } else if (campaingDetails?.status === "pending") {
      setFormData({
        schedule: "",
        countryCode: "",
        delaySecond: 5,
        delayFromSecond: 3,
        delayToSecond: 10,
      });
      setMessageData("");
      setTableData([]);
      setPos({});
      setCsvKeys([]);
      setFileName("");
      setFileUrl("");
      setManualText("");
      setTextAreaData("");
    }
    setFileType(
      originalFile
        ? fileType
        : campaingDetails?.template_type === "pdf"
        ? "application/pdf"
        : "image/jpeg" || ""
    );
    setTemplateType(campaingDetails?.template_type || "");
  }, [campaingDetails, fileType, originalFile]);

  const validation = (values) => {
    console.log("Validation  : ", values);
    let errors = {};
    const regCountryCode = /^[0-9]/;
    // const regDelaySecond = /^[0-9]/;
    if (!values.countryCode) {
      errors.countryCode = "Country Code is Required";
    } else if (!regCountryCode.test(values.countryCode)) {
      errors.countryCode = "Enter Valid Country Code";
    }
    if (values.schedule) {
      const selectedDate = moment(values.schedule);
      const currentDate = moment();

      console.log("Selected Date : ", selectedDate);

      if (selectedDate.isBefore(currentDate)) {
        console.log("hahahahahahahahahaahahahaahahahaaha");
        errors.schedule =
          "Scheduled time cannot be earlier than the current time.";
        setFormData({
          ...formData,
          schedule: "",
        });
      } else {
        if (selectedDate.isSame(currentDate, "day")) {
          // Check only hours and minutes
          const selectedTime = selectedDate.format("HH:mm");
          const currentTime = currentDate.format("HH:mm");

          if (selectedTime < currentTime) {
            errors.schedule =
              "Scheduled time cannot be earlier than the current time.";
            setFormData({
              ...formData,
              schedule: "",
            });
          }
        } else if (errors?.schedule) {
          delete errors.schedule;
        }
      }
    }
    // if (!values.delaySecond) {
    //   errors.delaySecond = "Please enter valid delay time";
    // } else if (!regDelaySecond.test(parseInt(values.delaySecond))) {
    //   errors.delaySecond = "Please enter valid delay time";
    // } else if (parseInt(values.delaySecond) > 10) {
    //   errors.delaySecond = "Minimum 0 second and maximum 10 second";
    // }
    return errors;
  };

  const validateStep = (step) => {
    let stepErrors = {};

    if (templateType === "text") {
      switch (step) {
        case 0:
          if (!initialCampaignState.name) {
            stepErrors.name = "Campaign Name is Required!";
            break;
          }
          if (!initialCampaignState.customer) {
            stepErrors.customer = "Please Select Customer.";
          }
          break;
        case 1:
          if (!tableData || tableData.length === 0) {
            stepErrors.tableData = "Please import WhatsApp numbers.";
          }
          break;
        case 2:
          if (!messageData) {
            stepErrors.messageData = "Please add a message.";
          }
          break;
        default:
          break;
      }
    } else {
      switch (step) {
        case 0:
          if (!initialCampaignState.name) {
            stepErrors.name = "Campaign Name is Required!";
            break;
          }
          if (!initialCampaignState.customer) {
            stepErrors.customer = "Please Select Customer.";
          }
          break;
        case 1:
          if (!tableData || tableData.length === 0) {
            stepErrors.tableData = "Please import WhatsApp numbers.";
          }
          break;
        case 2:
          if (!file) {
            stepErrors.file = "Please import a PDF or Image.";
          }
          break;
        case 4:
          if (!messageData) {
            stepErrors.messageData = "Please add a message.";
          }
          break;
        default:
          break;
      }
    }

    setErrors(stepErrors);
    if (Object.keys(stepErrors).length > 0) {
      setSnackbarMessage(Object.values(stepErrors)[0]);
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
    }
    return Object.keys(stepErrors).length === 0;
  };

  const transformGuestList = (guestList) => {
    return guestList.map((guest) => {
      const { name, number: mobile_number, ...variables } = guest;
      return { name, mobile_number, variables };
    });
  };

  const saveStepData = async () => {
    const request = new FormData();

    if (step === 0) {
      request.append("name", initialCampaignState.name);
    }

    // Step 1: Import Numbers
    if (step === 1) {
      request.append(
        "guest_list",
        JSON.stringify(transformGuestList(tableData))
      );
      request.append("variables", JSON.stringify(csvKeys));
      if (templateType === "text") {
        request.append("template_type", "text");
      }
    }

    // Step 2: Import File (only if template_type is not text)
    if (step === 2 && templateType !== "text") {
      request.append(
        "template_url",
        fileType === "application/pdf" ? file : originalFile
      );
      request.append(
        "template_type",
        fileType === "application/pdf" ? "pdf" : "image"
      );
    }

    // Step 3: Edit PDF (only if template_type is not text)
    if (step === 3 && templateType !== "text") {
      request.append("variable_positions", JSON.stringify(pos));
    }

    // Step 4: Add Message (if template_type is text, this will be step 1)
    const messageStep = templateType === "text" ? 2 : 4;
    if (step === messageStep) {
      request.append("message", messageData);
    }

    // General data (common for all steps)
    request.append("country_code", formData.countryCode || "91");
    request.append("delay_second_from", formData.delayFromSecond);
    request.append("delay_second_to", formData.delayToSecond);
    request.append("client_campaign_id", id);
    request.append("client_id", campaingDetails?.client_event?.id);
    request.append("is_save", true);
    request.append("current_step", step);

    if (!formData.schedule) {
      request.append("scheduler_date", "");
    } else {
      request.append("scheduler_date", formData.schedule);
    }

    try {
      setLoading(true);
      const response = await dispatch(updateEvent(request));
      setLoading(false);

      // console.log("Response ..... ", response);

      if (!response.payload.success) {
        setSnackbarMessage(response.payload.message);
        setSnackbarVariant("toast-danger");
        setShowSnackbar(true);
        return false;
      }
      return true;
    } catch (error) {
      setSnackbarMessage("Failed to save data: " + error.message);
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
      setLoading(false);
      return false;
    }
  };

  const handleNext = async () => {
    if (validateStep(step)) {
      console.log("Create Campaign ::::::::", formError);
      if (case_type === "new") {
        //create

        setIsSubmitting(true);
        const formData = new FormData();
        formData.append("client_id", initialCampaignState.customer);
        formData.append("name", initialCampaignState.name);
        formData.append("_method", "POST");
        formData.append("template_type", initialCampaignState.sendOption === 'message'? 'text' : null);

        try {
          const response = await dispatch(addCampaign(formData));

          if (response.payload.success) {
            const newCampaignId = response.payload.data.id; // Assuming backend sends the new campaignId
            setSnackbarMessage("Campaign created successfully.");
            setSnackbarVariant("toast-success");
            setShowSnackbar(true);

            setTimeout(() => {
              // Redirect to update campaign page after creating
              navigate(`/customers/update-campaign/${newCampaignId}`);
              setStep(1);
            }, 1000); // Delay to show the success message before redirect
          } else {
            setSnackbarMessage("Failed to create campaign.");
            setSnackbarVariant("toast-danger");
            setShowSnackbar(true);
          }
        } catch (error) {
          setSnackbarMessage("An error occurred while creating the campaign.");
          setSnackbarVariant("toast-danger");
          setShowSnackbar(true);
        } finally {
          setIsSubmitting(false);
        }
      } else if (case_type === "update") {
        const isSaved = await saveStepData();
        if (isSaved) {
          setStep((prevStep) =>
            prevStep < steps.length - 1 ? prevStep + 1 : prevStep
          );
        }
      }
    }
  };

  const handlePrev = () => {
    setStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep));
  };

  const handleFinish = async () => {
    setIsSubmitting(true);
    // console.log("Fnish");
    const formErrors = validation(formData);

    console.log("Form Error ff: ", formError);
    setFormError(formErrors);
    console.log("FORM ERROR : ", formData);

    if (Object.keys(formErrors).length > 0) {
      setSnackbarMessage(Object.values(formErrors)[0]);
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
      setIsSubmitting(false);
      return;
    }
    console.log("FORM ERROR : ", formError);

    if (!validateStep(step)) {
      setIsSubmitting(false);

      return;
    }
    const transformedGuests = transformGuestList(tableData);

    const request = new FormData();
    request.append(
      "template_url",
      templateType === "text"
        ? ""
        : fileType === "application/pdf"
        ? file
        : originalFile
    );
    request.append(
      "template_type",
      templateType === "text"
        ? "text"
        : fileType === "application/pdf"
        ? "pdf"
        : "image"
    );
    request.append("message", messageData);
    request.append("country_code", formData.countryCode || "91");
    request.append("delay_second", formData.delaySecond);
    request.append("delay_second_from", formData.delaySecond);
    request.append("delay_second_to", formData.delaySecond);
    if (!formData.schedule) {
      request.append("scheduler_date", "");
    } else {
      request.append("scheduler_date", formData.schedule);
    }
    request.append("variable_positions", JSON.stringify(pos));
    request.append("variables", JSON.stringify(csvKeys));
    request.append("guest_list", JSON.stringify(transformedGuests));
    request.append("client_campaign_id", id);
    request.append("client_id", campaingDetails?.client_event?.id);

    dispatch(updateEvent(request))
      .then(async (res) => {
        if (res.payload.success) {
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("toast-success");
          setShowSnackbar(true);
          setIsSubmitting(false);

          navigate(`/customers/view-campaign/${id}`);
        } else {
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("toast-danger");
          setShowSnackbar(true);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to add event: " + error.message);
        setSnackbarVariant("toast-danger");
        setShowSnackbar(true);
        setIsSubmitting(false);
      });
  };
  const handleSave = async () => {
    setIsSubmitting(true);
    if (!validateStep(step)) {
      setIsSubmitting(false);
      return;
    }

    const transformGuestList = (guestList) => {
      return guestList.map((guest) => {
        const { name, number: mobile_number, ...variables } = guest;
        return { name, mobile_number, variables };
      });
    };

    const transformedGuests = transformGuestList(tableData);

    const request = new FormData();

    // console.log("SAVE ::::: ", file);
    request.append(
      "template_url",
      fileType === "application/pdf" ? file : originalFile
    );
    request.append(
      "template_type",
      templateType === "text"
        ? "text"
        : fileType === "application/pdf"
        ? "pdf"
        : "image"
    );
    request.append("message", messageData);
    request.append("country_code", formData.countryCode || "91");
    request.append("delay_second", formData.delaySecond);
    request.append("delay_second_from", formData.delayFromSecond);
    request.append("delay_second_to", formData.delayToSecond);
    if (!formData.schedule) {
      request.append("scheduler_date", "");
    } else {
      request.append("scheduler_date", formData.schedule);
    }
    request.append("variable_positions", JSON.stringify(pos));
    request.append("variables", JSON.stringify(csvKeys));
    request.append("guest_list", JSON.stringify(transformedGuests));
    request.append("client_campaign_id", id);
    request.append("client_id", campaingDetails?.client_event?.id);
    request.append("is_save", true);

    dispatch(updateEvent(request))
      .then(async (res) => {
        if (res.payload.success) {
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("toast-success");
          setShowSnackbar(true);
          setIsSubmitting(false);

          navigate(`/customers/${campaingDetails?.client_event?.id}`);
        } else {
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("toast-danger");
          setShowSnackbar(true);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to add event: " + error.message);
        setSnackbarVariant("toast-danger");
        setShowSnackbar(true);
        setIsSubmitting(false);
      });
  };

  const handleBackClick = () => {
    setShowExitModal(true); // Show the exit confirmation modal
  };

  const handleConfirmExit = (id) => {
    console.log("campaingDetailscampaingDetailscampaingDetailscampaingDetails ::::: ", campaingDetails);
    navigate(`/customers/${id}`); // Redirect to customers page
  };

  const handleCancelExit = () => {
    setShowExitModal(false); // Close the exit confirmation modal
  };

  const handleSaveAsDraft = async () => {
    setIsSubmitting(true);
    const isSaved = await saveStepData(); // Reuse the save logic from your current steps

    if (isSaved) {
      navigate(`/customers/${campaingDetails?.client_event?.id}`); // Redirect to the customers page after saving
    }

    setIsSubmitting(false); // Reset submitting state
  };

  return (
    <CommonLayout>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Container fluid className="px-0">
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            className="mt-0"
          >
            <BackPageTab
              to="#"
              onClick={() => handleBackClick()}
              icon={
                <ArrowLeftIcon
                  width={22}
                  height={22}
                  color="#444050"
                  strokeWidth="2.2"
                />
              }
              title="New Camaingns"
            />
          </Col>
          <Col xs={12} lg={12} xl={3} xxl={3} className="mt-4">
            <ProgressBar steps={steps} currentStep={step} />
          </Col>
          <Col xs={12} lg={12} xl={9} xxl={9} className="mt-4">
            <WizardStep
              step={steps[step]}
              currentStep={step}
              formData={formData}
              setFormData={setFormData}
              formError={formError}
              setFormError={setFormError}
              inputdata={inputdata}
              setTotalPages={setTotalPages}
              totalPages={totalPages}
              setFileType={setFileType}
              fileType={fileType}
              setFile={setFile}
              file={file}
              setPagesUrl={setPagesUrl}
              pagesUrl={pagesUrl}
              messageData={messageData}
              setMessageData={setMessageData}
              tableData={tableData}
              setTableData={setTableData}
              csvKeys={csvKeys}
              setCsvKeys={setCsvKeys}
              setPos={setPos}
              pos={pos}
              validateStep={validateStep}
              errors={errors}
              manualText={manualText}
              setManualText={setManualText}
              textAreaData={textAreaData}
              setTextAreaData={setTextAreaData}
              validation={validation}
              setFileName={setFileName}
              fileName={fileName}
              setSelectedImage={setSelectedImage}
              selectedImage={selectedImage}
              setOriginalFile={setOriginalFile}
              originalFile={originalFile}
              campaingDetails={campaingDetails}
              isTestOpen={isTestOpen}
              setIsTestOpen={setIsTestOpen}
              handleTestClick={handleTestClick}
              fileUrl={fileUrl}
              setFileUrl={setFileUrl}
              templateType={templateType}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarVariant={setSnackbarVariant}
              setShowSnackbar={setShowSnackbar}
              setInitialCampaignState={setInitialCampaignState}
              initialCampaignState={initialCampaignState}
              clientFromQuery={clientFromQuery}
            />
            <div
              className={`d-flex align-items-center gap-4 ${
                step > 0 ? "justify-content-between" : "justify-content-end"
              } `}
            >
              <div className="d-flex align-items-center gap-3">
                {step < steps.length - 1 && (
                  <CommomButton
                    title={isSubmitting ? "Saving Draft..." : "Save as Draft"}
                    icon={
                      <NoteEditIcon
                        width={17}
                        height={17}
                        color="#7367F0"
                        strokeWidth="2.2"
                      />
                    }
                    onClick={handleSaveAsDraft}
                    disabled={isSubmitting}
                    className="btn-outline-primary bg-transparent"
                    subClassName="gap-2"
                  />
                )}
                {step === steps.length - 1 && (
                  <div className="d-flex align-items-center gap-3">
                    <CommomButton
                      title="Test Message"
                      icon={
                        <SentIcon
                          width={17}
                          height={17}
                          color="#7367F0"
                          strokeWidth="2.2"
                        />
                      }
                      onClick={() => handleTestClick()}
                      disabled={isSubmitting}
                      className="btn-outline-primary bg-transparent"
                      subClassName="gap-2"
                    />
                    <CommomButton
                      title={isSubmitting ? "Saving..." : "Save"}
                      icon={
                        <NoteEditIcon
                          width={17}
                          height={17}
                          color="#7367F0"
                          strokeWidth="2.2"
                        />
                      }
                      onClick={handleSave}
                      disabled={isSubmitting}
                      className="btn-outline-primary bg-transparent"
                      subClassName="gap-2"
                    />
                    {/* <CommomButton
                      title={
                        isSubmitting
                          ? "Sending..."
                          : formData?.schedule
                          ? "Send Scheduled"
                          : "Send"
                      }
                      icon={
                        <SentIcon
                          width={17}
                          height={17}
                          color="#7367F0"
                          strokeWidth="2.2"
                        />
                      }
                      onClick={handleFinish}
                      disabled={isSubmitting}
                      className="btn-outline-primary bg-transparent"
                      subClassName="gap-2"
                    /> */}
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center gap-3">
                <CommomButton
                  title="Previous"
                  disabled={step < 1}
                  icon={
                    <ArrowLeft02Icon
                      width={17}
                      height={17}
                      color="#6d6b77"
                      strokeWidth="2.2"
                    />
                  }
                  onClick={handlePrev}
                  className="btn-muted"
                  subClassName="gap-2 align-items-center"
                />

                {step < steps.length - 1 ? (
                  <CommomButton
                    title="Next"
                    type="submit"
                    icon={
                      <ArrowRight02Icon
                        width={17}
                        height={17}
                        color="#ffffff"
                        strokeWidth="2.2"
                      />
                    }
                    onClick={() => handleNext()}
                    className="btn-primary primary-shadow "
                    subClassName="flex-row-reverse align-items-center gap-2"
                  />
                ) : (
                  <CommomButton
                    title={
                      isSubmitting
                        ? "Sending..."
                        : formData?.schedule
                        ? "Send Scheduled"
                        : "Send"
                    }
                    icon={
                      <ArrowRight02Icon
                        width={17}
                        height={17}
                        color="#ffffff"
                        strokeWidth="2.2"
                      />
                    }
                    onClick={handleFinish}
                    disabled={isSubmitting}
                    className="btn-primary primary-shadow "
                    subClassName="flex-row-reverse align-items-center gap-2"
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={showExitModal} onHide={handleCancelExit} centered>
        <ModalHeader closeButton>
          <h3 className="text-danger mb-0">Exit Confirmation</h3>
        </ModalHeader>
        <ModalBody>
          <p>
            Are you sure you want to exit? All unsaved changes will be lost.
          </p>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <Button variant="secondary" onClick={handleCancelExit}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => handleConfirmExit(campaingDetails?.client_event?.id)}>
            Exit
          </Button>
        </ModalFooter>
      </Modal>
    </CommonLayout>
  );
};

const CheckEvent = ({ case_type, initial_step }) => {
  const { state } = useLocation();
  return state && state.connectionId ? (
    <EventIndex case_type={case_type} initial_step={initial_step} />
  ) : (
    <EventIndex case_type={case_type} initial_step={initial_step} />
  );
};

export default CheckEvent;
