import React, { useState } from "react";
import useMounted from "../../hooks/useMounted";
import Alerts from "../alerts/index";
import {
  Dropdown,
  Image,
  Button,
  ToastContainer,
  Toast,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogout } from "../../store/authSlice";
import {
  UserStoryIcon,
  WalletDone01Icon,
  Logout03Icon,
  CheckmarkBadge04Icon,
} from "../../icons/icons";

export default function NavigationBar() {
  const credits = useSelector((state) => state.auth.user?.credits);

  const hasMounted = useMounted();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("toast-success");

  const user = useSelector((state) => state.auth.user);

  const handleLogOut = async () => {
    try {
      await dispatch(userLogout()).then((res) => {
        setToastMessage(res.payload.message || "Logout successful");
        setToastVariant("toast-success");
        setShowToast(true);
        setTimeout(() => {
          if (hasMounted) {
            navigate("/", { replace: true });
          }
        }, 2000);
      });
    } catch (error) {
      console.error("Logout failed:", error);
      setToastMessage("Logout failed");
      setToastVariant("toast-danger");
      setShowToast(true);
      setTimeout(() => {
        if (hasMounted) {
          navigate("/", { replace: true });
        }
      }, 2000);
    } finally {
      setTimeout(() => {
        if (hasMounted) {
          navigate("/", { replace: true });
        }
      }, 2000);
    }
  };

  // const getUserInitial = (name) => {
  //   return name ? name.charAt(0).toUpperCase() : "U";
  // };

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          bg={toastVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{toastMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      {credits < 100 && (
        <Alerts
          key="warning"
          title=" Your credits are below 100. Please top up soon!"
          className="warning-alert position-fixed  mt-2 top-3 end-0 me-3 z-9999"
        />
      )}

      <div className="px-4 py-2 bg-white position-sticky custom-navigationbar">
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="fw-semibold text-capitalize pg-title">Dashboard</h5>

          <Dropdown className="profile-menu" align="end">
            <Dropdown.Toggle
              variant="link"
              id="dropdown-basic"
              className="position-relative p-0 profile-toggle"
            >
              <Image
                src="/assets/images/navigation-bar/dummy-user.png"
                className="h-100 w-100 d-inline-block"
                alt={`${user?.name} Image`}
                roundedCircle
              />
              {/* {getUserInitial(user?.name)} */}
            </Dropdown.Toggle>

            <Dropdown.Menu className="mt-4 p-2 profile-submenu">
              <Dropdown.Item
                href="#/action-1"
                className="py-2 px-3 profile-menu-item"
              >
                <div className="d-flex align-items-center gap-2">
                  <div className="position-relative flex-shrink-1 profile-user">
                    <Image
                      src="/assets/images/navigation-bar/dummy-user.png"
                      className="h-100 w-100 d-inline-block"
                      alt={`${user?.name} Image`}
                      roundedCircle
                    />
                  </div>
                  <div className="overflow-hidden flex-grow-1">
                    <h6 className="fw-medium text-capitalize mb-0">
                      {user?.name}
                    </h6>
                    <div className="text-capitalize post-tag">Web Designer</div>
                  </div>
                </div>
              </Dropdown.Item>
              <div className="profile-submenu-divider"></div>
              <Dropdown.Item
                href="/profile"
                className="py-2 px-3 profile-menu-item"
              >
                <div className="d-flex align-items-center gap-2">
                  <UserStoryIcon
                    width={19}
                    height={19}
                    color="#444050"
                    strokeWidth="2"
                  />
                  <span className="ms-1 text-capitalize fw-normal">
                    My Profile
                  </span>
                </div>
              </Dropdown.Item>
              <Dropdown.Item
                href="/wallet"
                className="py-2 px-3 profile-menu-item"
              >
                <div className="d-flex align-items-center gap-2">
                  <WalletDone01Icon
                    width={19}
                    height={19}
                    color="#444050"
                    strokeWidth="2"
                  />
                  <span className="ms-1 text-capitalize fw-normal">Wallet</span>
                </div>
              </Dropdown.Item>
              <div className="profile-submenu-divider"></div>
              <Dropdown.Item href="#" className="pt-2 pb-1 px-3  logout-btn">
                <Button
                  variant="link"
                  onClick={handleLogOut}
                  className="w-100 text-white d-flex align-items-center justify-content-center text-decoration-none gap-2"
                >
                  Logout
                  <span>
                    <Logout03Icon
                      width={15}
                      height={15}
                      color="#ffffff"
                      strokeWidth="2"
                    />
                  </span>
                </Button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
}
