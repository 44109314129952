import React from "react";
import Sidebar from "../../../components/sidebar/index";
import NavigationBar from "../../../components/navigationbar/index";
import { Container } from "react-bootstrap";
import "../../../styles/theme.scss";
// import Sidebar from './navbar/Sidebar';
// import NavbarTop from "./navbar/NavbarTop";

export default function DashboardLayout({ children }) {
  // const [showMenu, setShowMenu] = useState(true);

  // const ToggleMenu = () => {
  //   return setShowMenu(!showMenu);
  // };

  return (
    <div className="h-100 position-relative main-content">
      {/* <Sidebar className="position-fixed start-0 top-0 bottom-0" /> */}
      <Sidebar className="position-fixed start-0 top-0 bottom-0" />

      <div className="position-relative pg-content">
        <Container className="px-4 mx-auto pg-container">
          <NavigationBar />
          <main className="py-4 mt-3">{children}</main>
        </Container>
      </div>
    </div>
  );
}
