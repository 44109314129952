import React, { useEffect, useState, useCallback } from "react";
import CommomButton from "../../../components/common-button/index";
import NavTabs from "../../../components/nav-tabs/index";
import Input from "../../../components/input/index";
import CommonActionDropDown from "../../../components/common-action-dropdown/index";
import CommonPagination from "../../../components/common-pagination/index";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  Button,
  Toast,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // Tabs,
  // Tab,
  FormCheck,
  // OverlayTrigger,
  // Tooltip,
  Card,
  ToastContainer,
} from "react-bootstrap";
import {
  fetchEventDetail,
  addCampaign,
  fetchEvents,
  deleteCampaign,
} from "../../../store/eventsSlice";
import { Container, Col, Row } from "react-bootstrap";
// import { MoreVertical } from "react-feather";
import { Link, useParams, useNavigate } from "react-router-dom";
// import SortImage from "../../../sort.svg";
// import PaginationComponent from "../pagination/pagination";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import "./styleModule.css";
import { useLoader } from "../../../context/LoaderContext";
import axios from "axios";
import moment from "moment";
// import { ArrowLeft } from "../../../constants/icons/icons";
import Edit from "../events/Edit";
import {
  WhatsappIcon,
  SmartPhone01Icon,
  RssErrorIcon,
  Add01Icon,
  MoreVerticalIcon,
  CheckmarkBadge04Icon,
} from "../../../icons/icons";
import { CampaignsDataTabs } from "../../../constants/pages/events/detail/index";
import DeletePopup from "../../../components/delete-popup";

export default function List({ editHandler, handleOpenModal, client_id }) {
  const dispatch = useDispatch();
  const { setLoading } = useLoader();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");
  const campaings = useSelector((state) => state.events?.campaings);
  const totalPages = useSelector((state) => state.events.totalPages);
  const total = useSelector((state) => state.events.total);
  const [currentPage, setCurrentPage] = useState(0);
  const [limit] = useState(10);
  const [searchTerm] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [showConnentModal, setShowConnectModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [activeTab, setActiveTab] = useState("all");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const [isScanSuccessful, setIsScanSuccessful] = useState(false);
  const [ws, setWs] = useState(null);

  const [showDisconnectConfirmModal, setShowDisconnectConfirmModal] =
    useState(false);

  const customer_id = useSelector((state) => state.auth.user.id);

  console.log(ws);
  console.log(isScanSuccessful);

  const [qrCode, setQrCode] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const event = useSelector(
    (state) =>
      state.events.events.filter((event) => event.id === parseInt(id))[0]
  );

  const initialCampaignState = {
    name: "",
    sendOption: "invitation", // Default selection for radio button
  };

  const campaignValidation = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    sendOption: Yup.string().required("Send option is required"),
  });

  const getEventsList = useCallback(() => {
    setLoading(true);
    dispatch(
      fetchEvents({ offset: 0, limit, search: searchTerm, id: customer_id })
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, limit, searchTerm, customer_id, setLoading]);

  useEffect(() => {
    getEventsList();
  }, [getEventsList]);

  const getEventsDetailsData = useCallback(() => {
    setLoading(true);
    dispatch(
      fetchEventDetail({
        offset: currentPage,
        limit,
        search: searchTerm,
        id: client_id,
        status: activeTab,
      })
    )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    currentPage,
    limit,
    searchTerm,
    client_id,
    setLoading,
    activeTab,
  ]);

  useEffect(() => {
    getEventsDetailsData();
  }, [getEventsDetailsData]);

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber - 1);
  };

  const handleConnect = async (mobile_number) => {
    try {
      setIsScanSuccessful(!isScanSuccessful);
      const response = await axios.post(
        `${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/connect`,
        {
          clientId: id,
          wsId: `ws_connect_${id}`,
          userId: id,
        }
      );

      // console.log("Response ::::: ", response);
      // if (response.data.success) {
      //   // Start polling for QR code every 2 seconds
      //   const intervalId = setInterval(async () => {
      //     try {
      //       const response_qr = await axios.post(
      //         `${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/qr`,
      //         {
      //           clientId: id,
      //           wsId: `ws_connect_${id}`,
      //           userId: id,
      //         }
      //       );

      //       console.log("Qr :::::::", response_qr);

      //       // Check if QR code is available
      //       if (response_qr.data.qrcode) {
      //         setQrCode(response_qr.data.qrcode);
      //         clearInterval(intervalId); // Stop polling once we have the QR code
      //       }
      //     } catch (error) {
      //       console.error("Error fetching QR Code:", error);
      //     }
      //   }, 2000); // Poll every 2 seconds
      // }
      setQrCode(response.data.qrString);
    } catch (error) {
      console.error("Error connecting to WhatsApp:", error);
      setSnackbarMessage("Failed to connect to WhatsApp");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
      setIsScanSuccessful(false)
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
    >
      {children}
    </Link>
  ));

  CustomToggle.displayName = "CustomToggle";
  
  // const navigateToDetails = (id) => {
  //   navigate(`/customers/${id}`);
  // };

  // const createCampaign = async () => {
  //   const currentDateTime = moment().format("DD-MM-YYYY hh:mm A");
  //   const defaultCampaignName = `New Campaign - ${currentDateTime}`;
  
  //   try {
  //     setLoading(true);
  //     // Create a new campaign with default values
  //     const formData = new FormData();
  //     formData.append("client_id", client_id);
  //     formData.append("name", defaultCampaignName);
  //     formData.append("sendOption", "invitation");  // Default to invitation
  
  //     const response = await dispatch(addCampaign(formData));

  //     console.log("Response ::::::::: ", response);
  
  //     if (response.payload.success) {
  //       setSnackbarMessage(response.payload.message);
  //       setSnackbarVariant("toast-success");
  //       setShowSnackbar(true);
  //       // Redirect to the first step of the campaign creation process
  //       navigate(`/customers/${client_id}/add-campaign/${response.payload.data.id}`);
  //     } else {
  //       setSnackbarMessage(response.payload.message);
  //       setSnackbarVariant("toast-danger");
  //       setShowSnackbar(true);
  //     }
  //   } catch (error) {
  //     setSnackbarMessage("Failed to create campaign");
  //     setSnackbarVariant("toast-danger");
  //     setShowSnackbar(true);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  

  const handleAddClick = () => {
    // setAddModal(true);
    // if (!event?.whatsapp_client?.is_active) {
    //   setCurrentStep(1);
    // } else {
    //   setCurrentStep(2);
    // }
    // navigate(
    //   `/customers/${client_id}/add-campaign/${id}`
    // );
    if(!event?.whatsapp_client?.is_active){
      setShowConnectModal(true)
    }else{
      navigate(
        `/customers/new-campaign?client=${client_id}`
      );
    }
    
  };


  // const handleAddClick = async () => {
  //   if (event?.whatsapp_client?.is_active) {
  //     // WhatsApp is connected, proceed with campaign creation
  //     createCampaign();
  //   } else {
  //     // WhatsApp is not connected, show the connection modal
  //     setShowConnectModal(true);
  //   }
  // };


  // const handleAddClick = async () => {
  //   if (event?.whatsapp_client?.is_active) {
  //     // WhatsApp is connected, proceed with campaign creation
  //     createCampaign();
  //   } else {
  //     // WhatsApp is not connected, show the connection modal
  //     setShowConnectModal(true);
  //   }
  // };

  const handleOpenConnectModal = () => {
    setShowConnectModal(true);
  };

  const handleCloseConnectModal = useCallback(() => {
    setShowConnectModal(false);
    setQrCode("");
    getEventsDetailsData();
    getEventsList();
  }, [getEventsDetailsData, getEventsList]);

  const handleCloseClickConnectModal = useCallback(() => {
    setShowConnectModal(false);
    setQrCode("");
    setIsScanSuccessful(false);
    // getEventsDetailsData();
    // getEventsList();
  }, []);

  const toggleAddModal = () => {
    setQrCode("");
    setIsScanSuccessful(false);
    setAddModal(!addModal);
    setCurrentStep(1);
  };

  const handleSendMessageClick = (id) => {
    navigate(`/customers/update-campaign/${id}`);
  };

  useEffect(() => {
    const socket = new WebSocket(process.env.REACT_APP_SOCKET_URL);
    socket.onopen = () => {
      console.log("WebSocket Client Connected");
      const wsId = `ws_connect_${id}`;
      socket.send(JSON.stringify({ type: "REGISTER", id: wsId }));
      setWs(socket);
    };

    socket.onmessage = (message) => {
      const data = JSON.parse(message.data);

      console.log("data.status : ", data);
      if (data.status === "connected") {
        setSnackbarMessage("WhatsApp connected successfully");
        setSnackbarVariant("toast-success");
        setShowSnackbar(true);
        setIsScanSuccessful(true);
        handleCloseConnectModal();
        // setCurrentStep(2);
        // createCampaign()
      } else if (data.status === "disconnected") {
        setSnackbarMessage("Failed to connect to WhatsApp");
        setSnackbarVariant("toast-danger");
        setShowSnackbar(true);
        setIsScanSuccessful(false);
      }
    };

    socket.onclose = () => {
      console.log("WebSocket Client Disconnected");
    };

    return () => {
      socket.close();
    };
  }, [id, handleCloseConnectModal]);

  const handleDeleteCampaignClick = (campaing) => {
    setSelectedCampaign(campaing); // Set the selected campaign to be deleted
    setShowDeleteConfirmModal(true); // Show the confirmation modal
  };

  const handleConfirmDelete = async () => {
    try {
      setIsSubmitting(true); // Set loading state
      setLoading(true); // Optional loader if you have one
      dispatch(deleteCampaign({ id: selectedCampaign.id }));
      setSnackbarMessage("Campaign deleted successfully");
      setSnackbarVariant("toast-success");
      setShowSnackbar(true);
      getEventsList(); // Refresh the campaigns list
    } catch (error) {
      setSnackbarMessage("Failed to delete campaign");
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
    } finally {
      setIsSubmitting(false);
      setLoading(false); // Optional loader
      setShowDeleteConfirmModal(false);
    }
  };

  // const ActionMenu = ({ campaing, showSend }) => {
  //   return (
  //     <Dropdown drop="up" className="position-static">
  //       <Dropdown.Toggle as={CustomToggle}>
  //         <MoreVertical size="15px" className="text-muted" />
  //       </Dropdown.Toggle>
  //       <Dropdown.Menu align={"end"}>
  //         {(campaing.status === "pending" || campaing.status === "drafted") && (
  //           <Dropdown.Item
  //             eventKey="1"
  //             onClick={() => handleSendMessageClick(campaing.id)}
  //           >
  //             Send Message
  //           </Dropdown.Item>
  //         )}
  //         <Dropdown.Item
  //           eventKey="2"
  //           onClick={() => {
  //             navigate(`/customers/view-campaign/${campaing.id}`);
  //           }}
  //         >
  //           View
  //         </Dropdown.Item>
  //         {(campaing.status === "pending" ||
  //           campaing.status === "drafted" ||
  //           campaing.status === "scheduled") && (
  //           <Dropdown.Item
  //             eventKey="3"
  //             // onClick={() => {
  //             //   navigate(`/customers/view-campaign/${campaing.id}`);
  //             // }}
  //             onClick={() => handleDeleteCampaignClick(campaing)}
  //           >
  //             Delete
  //           </Dropdown.Item>
  //         )}
  //       </Dropdown.Menu>
  //     </Dropdown>
  //   );
  // };

  const getCampaignActions = (campaign) => {
    const actions = [];
  
    // Show "Send Message" action if campaign status is 'pending' or 'drafted'
    if (campaign.status === 'pending' || campaign.status === 'drafted') {
      actions.push({
        title: 'Send Message',
        handler: () => handleSendMessageClick(campaign.id),
      });
    }
  
    // Show "View" action for all campaigns
    actions.push({
      title: 'View',
      handler: () => {
        navigate(`/customers/view-campaign/${campaign.id}`);
      },
    });
  
    // Show "Delete" action for 'pending', 'drafted', or 'scheduled' campaigns
    if (
      campaign.status === 'pending' ||
      campaign.status === 'drafted' ||
      campaign.status === 'scheduled'
    ) {
      actions.push({
        title: 'Delete',
        handler: () => handleDeleteCampaignClick(campaign),
      });
    }
  
    return actions;
  };
  

  // const handleBackClick = () => {
  //   navigate("/customers");
  // };

  const handleDisconnectClick = () => {
    setShowDisconnectConfirmModal(true);
  };

  const handleConfirmDisconnect = async () => {
    try {
      setIsSubmitting(true);
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_WHATSAPP_API_URL}/api/whatsapp/disconnect`,
        { clientId: client_id }
      );
      if (response.data.success) {
        setSnackbarMessage("Disconnected successfully");
        setSnackbarVariant("toast-success");
        setShowSnackbar(true);
        getEventsList();
      } else {
        throw new Error(response.data.message || "Failed to disconnect");
      }
    } catch (error) {
      setSnackbarMessage(error.message);
      setSnackbarVariant("toast-danger");
      setShowSnackbar(true);
    } finally {
      setLoading(false);
      setIsSubmitting(false);
      setShowDisconnectConfirmModal(false);
    }
  };

  const handleTabSelect = (key) => {
    setActiveTab(key);
    setCurrentPage(0);
  };

  const getColumnsForActiveTab = () => {
    const commonColumns = [
      {
        label: "Name",
        dataKey: "name",
      },
      {
        label: "Send Type",
        dataKey: "template_type",
      },
      {
        label: "Status",
        dataKey: "status",
      },
    ];

    const dynamicColumns = [];

    switch (activeTab) {
      case "scheduled":
        dynamicColumns.push({
          label: "Scheduled Date",
          dataKey: "scheduler_date",
          format: (value) => moment(value).format("DD-MM-YYYY hh:mm A"),
        });
        break;
      case "completed":
        dynamicColumns.push({
          label: "From Number",
          dataKey: "from_number",
        });
        break;
      case "in-process":
        dynamicColumns.push({
          label: "From Number",
          dataKey: "from_number",
        });
        break;
      case "failed":
        dynamicColumns.push({
          label: "Failed Reason",
          dataKey: "failed_reason",
        });
        dynamicColumns.push({
          label: "From Number",
          dataKey: "from_number",
        });
        break;
      default:
        break;
    }

    const remainingColumns = [
      // {
      //   label: "Recipients",
      //   dataKey: "client_guests",
      //   format: (value) =>
      //     Array.isArray(value)
      //       ? value.filter((guest) => !guest.is_test).length
      //       : 0,
      // },
      {
        label: "Credits Used",
        dataKey: "total_credits",
      },
      {
        label: "Created At",
        dataKey: "created_at",
        format: (value) => moment(value).format("DD-MM-YYYY hh:mm A"),
      },
    ];

    if (activeTab !== "pending" && activeTab !== "drafted") {
      remainingColumns.unshift({
        label: "Recipients",
        dataKey: "client_guests_not_test_count",
        // format: (value) =>
        //   Array.isArray(value)
        //     ? value.filter((guest) => !guest.is_test).length
        //     : 0,
      });
    }

    return [...commonColumns, ...dynamicColumns, ...remainingColumns];
  };

  const handleEditClick = (customer) => {
    setShowEditModal(true); // Show the Edit modal
  };

  const handleEditClose = () => {
    getEventsList();
    setShowEditModal(false);
  };

  const getUserInitial = (name) => {
    return name
      ? name
          .split(" ")
          .map((n) => n.charAt(0))
          .join("")
      : "UN";
  };

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Container fluid className="px-0">
        {/* <Row>
          <Col xl={3} lg={12} md={12} xs={12}>
            <Row className="gy-4">
              <Col xl={12} lg={6} md={12} xs={12}>
                <div className="bg-white rounded-3 p-4 h-100">
                  <div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center gap-2 fw-semibold sub-heading">
                        <span className="rounded-circle"></span>
                        <p className="mb-0">Personal Details</p>
                      </div>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">Edit</Tooltip>}
                      >
                        <div
                          className="cursor-pointer"
                          onClick={() => handleEditClick()}
                        >
                          <EDT size={20}></EDT>
                        </div>
                      </OverlayTrigger>
                    </div>
                    <div className="d-flex flex-column mt-3 customer-de-card">
                      <div className="d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center re-title ">
                          <p className="mb-0 text-capitalize white-space-nowrap">
                            Name :{" "}
                          </p>
                        </div>
                        <p className="mb-0">{event?.name}</p>
                      </div>

                      <div className="d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center re-title ">
                          <p className="mb-0 text-capitalize white-space-nowrap">
                            Mobile No. :
                          </p>
                        </div>
                        <p className="mb-0 text-capitalize">
                          {event?.contact_number}
                        </p>
                      </div>

                      <div className="d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center re-title ">
                          <p className="mb-0 text-capitalize white-space-nowrap">
                            Email :
                          </p>
                        </div>
                        <p className="mb-0">{event?.email || "-"}</p>
                      </div>

                      <div className="d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center re-title ">
                          <p className="mb-0 text-capitalize white-space-nowrap">
                            Address :
                          </p>
                        </div>
                        <p className="mb-0 text-capitalize">
                          {event?.address || "-"}
                        </p>
                      </div>

                      <div className="d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center re-title ">
                          <p className="mb-0 text-capitalize white-space-nowrap">
                            Created on :
                          </p>
                        </div>
                        <p className="mb-0 text-capitalize">
                          {moment(event?.created_at).format(
                            "DD/MM/YYYY hh:mm A"
                          )}
                        </p>
                      </div>

                      <div className="d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center re-title ">
                          <p className="mb-0 text-capitalize white-space-nowrap">
                            Updated on :
                          </p>
                        </div>
                        <p className="mb-0 text-capitalize">
                          {moment(event?.updated_at).format(
                            "DD/MM/YYYY hh:mm A"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={12} lg={6} md={12} xs={12}>
                <div className="bg-white rounded-3 p-4 h-100">
                  <div>
                    <div className="d-flex align-items-center gap-2 fw-semibold sub-heading">
                      <span className="rounded-circle"></span>
                      <p className="mb-0">WhatsApp Details</p>
                    </div>

                    <div className="d-flex flex-column mt-3 customer-de-card">
                      <div className="d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center re-title ">
                          <p className="mb-0 text-capitalize white-space-nowrap">
                            Status :
                          </p>
                        </div>
                        <p
                          className={`mb-0 ${event?.whatsapp_client?.is_active
                            ? "text-success"
                            : "text-danger"
                            }`}
                        >
                          {event?.whatsapp_client?.is_active
                            ? "Connected"
                            : "Not Connected"}
                        </p>
                      </div>

                      <div className="d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center re-title ">
                          <p className="mb-0 text-capitalize white-space-nowrap">
                            Mobile No :
                          </p>
                        </div>
                        <p className="mb-0">
                          {event?.whatsapp_client?.is_active
                            ? event?.whatsapp_client?.mobile_number
                            : "-"}
                        </p>
                      </div>

                      <div className="d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center re-title ">
                          {event?.whatsapp_client?.is_active ? (
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Connected At :
                            </p>
                          ) : (
                            <p className="mb-0 text-capitalize white-space-nowrap">
                              Disconnected At :
                            </p>
                          )}
                        </div>
                        <p className="mb-0">
                          {event?.whatsapp_client?.last_sync_at
                            ? moment
                              .unix(event?.whatsapp_client?.last_sync_at)
                              .format("DD/MM/YYYY hh:mm A")
                            : "-"}
                        </p>
                      </div>
                      <div className="align-items-center gap-2">
                        {!event?.whatsapp_client?.is_active ? (
                          <Button
                            variant="primary"
                            onClick={() => handleOpenConnectModal()}
                          >
                            Connect
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            onClick={handleDisconnectClick}
                          >
                            Disconnect
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xl={9} lg={12} md={12} xs={12} className="mt-6">
            <div className="tablecontainer">
              <div className="mb-3 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-2 fw-semibold sub-heading">
                  <span className="rounded-circle"></span>
                  <p className="mb-0">Campaign List</p>
                </div>
                <Button variant="primary" onClick={() => handleAddClick()}>
                  New Campaign
                </Button>
              </div>
              <div className="position-relative overflow-hidden rounded-2 bg-white table-tabs">
                <Tabs
                  defaultActiveKey="all"
                  className="pb-0 table-tabs-items"
                  id="uncontrolled-tab-example"
                  onSelect={handleTabSelect}
                >
                  <Tab eventKey="all" title={<p className="mb-0">All</p>}></Tab>
                  <Tab
                    eventKey="pending"
                    title={<p className="mb-0">Pending</p>}
                  ></Tab>
                  <Tab
                    eventKey="drafted"
                    title={<p className="mb-0">Drafted</p>}
                  ></Tab>
                  <Tab
                    eventKey="scheduled"
                    title={<p className="mb-0">Scheduled</p>}
                  ></Tab>
                  <Tab
                    eventKey="in-process"
                    title={<p className="mb-0">In-process</p>}
                  ></Tab>
                  <Tab
                    eventKey="completed"
                    title={<p className="mb-0">Completed</p>}
                  ></Tab>
                  <Tab
                    eventKey="failed"
                    title={<p className="mb-0">Failed</p>}
                  ></Tab>
                </Tabs>
              </div>

              <div className="overflow-auto">
                <Table className="text-nowrap text-start">
                  <thead className="table-light">
                    <tr>
                      {getColumnsForActiveTab().map((column) => (
                        <th key={column.dataKey}>
                          <div className="d-flex justify-content-between align-items-center">
                            <span>{column.label}</span>
                          </div>
                        </th>
                      ))}
                      <th>
                        <div className="d-flex justify-content-between align-items-center">
                          <span>Action</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaings?.length === 0 ? (
                      <tr>
                        <td className="text-center" colSpan="7">
                          <div className="mt-5 fw-bold fs-5">
                            No data available
                          </div>
                        </td>
                      </tr>
                    ) : (
                      campaings?.map((campaing, index) => (
                        <tr
                          key={campaing?.id}
                          onClick={() => navigateToDetails(campaing?.id)}
                        >
                          {getColumnsForActiveTab().map((column) => (
                            <td
                              key={column.dataKey}
                              className="text-capitalize"
                            >
                              {column.format
                                ? column.format(campaing[column.dataKey])
                                : column.dataKey === "total_credits"
                                  ? campaing[column.dataKey] || 0
                                  : column.dataKey === "template_type"
                                    ? campaing[column.dataKey] === "text"
                                      ? "Only Message"
                                      : "With Invitation Card"
                                    : campaing[column.dataKey]}
                            </td>
                          ))}
                          <td>
                            <ActionMenu
                              campaing={campaing}
                              showSend={campaing?.message ? false : true}
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
                {totalPages > 1 ? (
                  <PaginationComponent
                    totalItems={total}
                    itemsPerPage={limit}
                    currentPage={currentPage + 1}
                    onPageChange={handlePageChange}
                  />
                ) : null}
              </div>
            </div>
          </Col>
        </Row> */}
        <Row>
          <Col xl={12} xxl={4}>
            <div className="d-flex align-content-start align-items-xxl-center flex-xxl-column gap-4 mb-4 mb-xxl-0">
              <Card className="rounded-2 p-4 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div className="d-flex justify-content-between align-items-center gap-3">
                    <h5 className="fw-medium text-capitalize">
                      Personal Details
                    </h5>
                    <Link
                      to="#"
                      onClick={() => handleEditClick()}
                      className="text-capitalize fw-medium text-decoration-none"
                    >
                      Edit
                    </Link>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <Link
                    to=""
                    className="d-flex align-items-center gap-2 text-decoration-none mb-4 card-profile"
                  >
                    <div className=" rounded-circle d-flex align-items-center justify-content-center flex-shrink-1 user-icon">
                      <span className="text-uppercase fw-normal">
                        {getUserInitial(event?.name)}
                      </span>
                    </div>
                    <h6 className="fw-medium ms-1 text-capitalize">
                      {event?.name}
                    </h6>
                  </Link>
                  <div className="d-flex flex-column gap-3 card-content mb-3 mb-xxl-4">
                    <div>
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Contact info
                      </h6>
                      <p className="mb-1 fw-normal">
                        Email: {event?.email || "-"}
                      </p>
                      <p className=" fw-normal">
                        Mobile: {event?.contact_number}
                      </p>
                    </div>
                    <div>
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Address info
                      </h6>
                      <p className="fw-normal">{event?.address || "-"}</p>
                    </div>
                  </div>
                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2 card-content">
                    <div className="g-col-12 g-col-xl-6">
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Created On
                      </h6>
                      <p className="fw-normal">
                        {moment(event?.created_at).format("DD/MM/YYYY hh:mm A")}
                      </p>
                    </div>
                    <div className="g-col-12 g-col-xl-6">
                      <h6 className=" fw-medium mb-1 text-capitalize">
                        Updated On
                      </h6>
                      <p className="fw-normal">
                        {moment(event?.updated_at).format("DD/MM/YYYY hh:mm A")}
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card className="rounded-2 p-4 border-0 common-shadow w-100 common-card">
                <Card.Header className="px-0 pt-0 pb-4 bg-transparent border-0 common-card-header">
                  <div>
                    <h5 className="fw-medium text-capitalize mb-1">
                      WhatsApp Details
                    </h5>
                    <p className="fw-medium">
                      <span>Connected At :</span> 10/09/2024 11:55 AM
                    </p>
                  </div>
                </Card.Header>
                <Card.Body className="p-0 common-card-body">
                  <div className="grid row-gap-3 column-gap-0 column-gap-xxl-2">
                    <div className="g-col-12 g-col-xl-6">
                      <div
                        className={`d-flex align-items-center status-content  ${
                          event?.whatsapp_client?.is_active
                            ? "success-status"
                            : "danger-status"
                        }`}
                      >
                        <div className=" rounded-2 d-flex align-items-center justify-content-center status-icon">
                          {event?.whatsapp_client?.is_active ? (
                            <WhatsappIcon
                              width={28}
                              height={28}
                              color="#28c76f"
                              strokeWidth="1.5"
                            />
                          ) : (
                            <RssErrorIcon
                              width={28}
                              height={28}
                              color="#ff4c51"
                              strokeWidth="1.5"
                            />
                          )}
                        </div>
                        <div>
                          <h6 className="fw-medium text-capitalize">
                            {event?.whatsapp_client?.is_active
                              ? "Connected"
                              : "Not Connected"}
                          </h6>
                          <span className="text-capitalize">Status</span>
                        </div>
                      </div>
                    </div>
                    <div className="g-col-12 g-col-xl-6">
                      <div className="d-flex align-items-center status-content primary-status">
                        <div className=" rounded-2 d-flex align-items-center justify-content-center status-icon">
                          <SmartPhone01Icon
                            width={28}
                            height={28}
                            color="#7367f0"
                            strokeWidth="1.5"
                          />
                        </div>
                        <div>
                          <h6 className="fw-medium text-capitalize">
                            {event?.whatsapp_client?.is_active
                              ? event?.whatsapp_client?.mobile_number
                              : "-"}
                          </h6>
                          <span className="text-capitalize">Mobile No</span>
                        </div>
                      </div>
                    </div>
                    <div className="g-col-12">
                      <CommomButton
                        title={
                          !event?.whatsapp_client?.is_active
                            ? " Connect "
                            : "Disconnect"
                        }
                        onClick={
                          !event?.whatsapp_client?.is_active
                            ? () => handleOpenConnectModal()
                            : handleDisconnectClick
                        }
                        className="w-100 btn-primary primary-shadow "
                      />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col xl={12} xxl={8}>
            <div className="mb-4">
              <NavTabs
                defaultActiveKey="all"
                id="uncontrolled-tab-example"
                onSelect={handleTabSelect}
                tabsData={CampaignsDataTabs}
              />
            </div>
            <div className="bg-white rounded-2 overflow-hidden common-shadow">
              <div className="p-4 d-flex align-items-center justify-content-between gap-4">
                <Input
                  type="search"
                  // value={searchTerm}
                  placeholder="Search Campaigns List"
                  // onChange={(e) => {
                  //   setSearchTerm(e.target.value);
                  //   setCurrentPage(0);
                  // }}
                  className="shadow-none base-search"
                />

                {/* Add Campaign */}
                <CommomButton
                  onClick={() => handleAddClick()}
                  icon={
                    <Add01Icon
                      width={16}
                      height={16}
                      color="#ffffff"
                      strokeWidth="2"
                    />
                  }
                  title="New Campaign"
                  className="btn-primary common-one-shadow"
                />
              </div>
              <Table responsive className="common-table">
                <thead>
                  <tr>
                    {getColumnsForActiveTab().map((item, i) => {
                      return (
                        <th key={i}>
                          <p>{item.label}</p>
                        </th>
                      );
                    })}
                    <th>
                      <p>Actions</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {campaings?.map((item, i) => {
                    return (
                      <tr key={i}>
                        {getColumnsForActiveTab().map((column) => {
                          return (
                            <td key={column.dataKey}>
                              {column.format
                                ? column.format(item[column.dataKey])
                                : column.dataKey === "total_credits"
                                ? item[column.dataKey] || 0
                                : column.dataKey === "template_type"
                                ? item[column.dataKey] === "text"
                                  ? "Only Message"
                                  : "With Invitation Card"
                                : item[column.dataKey]}
                            </td>
                          );
                        })}

                        <td>
                          <CommonActionDropDown
                            icon={
                              <MoreVerticalIcon
                                width={20}
                                height={20}
                                color="#6D6B77"
                                strokeWidth="4"
                              />
                            }
                            firstTitle="View"
                            firstHref=""
                            actions={getCampaignActions(item)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="p-3 mx-1 d-flex flex-wrap align-items-center justify-content-between gap-4">
                <p className="custom-table-infotext ">
                  Displaying 1 to 10 of {total} Organizations
                </p>
                <div>
                  {totalPages > 1 ? (
                    <CommonPagination
                      totalItems={total}
                      itemsPerPage={limit}
                      currentPage={currentPage + 1}
                      onPageChange={handlePageChange}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* New Campaign Modal */}
      <Modal show={addModal} onHide={toggleAddModal} centered>
        <ModalHeader closeButton>
          <h3 className="text-primary mb-0">
            {currentStep === 1 ? "Connect WhatsApp" : "New Campaign"}
          </h3>
        </ModalHeader>
        <ModalBody>
          {currentStep === 1 && (
            <div className="text-center">
              {qrCode ? (
                <>
                  <img src={qrCode} alt="WhatsApp QR Code" />
                  <p>Please open WhatsApp and scan the QR code</p>
                </>
              ) : (
                <p>Click the button below to connect to WhatsApp.</p>
              )}
            </div>
          )}
          {currentStep === 1 && (
            <div className="d-flex justify-content-center mt-3">
              <Button
                variant="primary"
                onClick={() => handleConnect()}
                disabled={isScanSuccessful || !!qrCode}
              >
                {qrCode ? "Connecting..." : "Connect"}
              </Button>
            </div>
          )}
          {currentStep === 2 && (
            <Formik
              initialValues={initialCampaignState}
              validationSchema={campaignValidation}
              onSubmit={async (
                values,
                { setSubmitting, resetForm, setErrors }
              ) => {
                setIsSubmitting(true);
                const formData = new FormData();
                formData.append("client_id", client_id);
                formData.append("_method", "POST");
                formData.append("name", values.name);

                // Conditionally append the template_type based on the selected send option
                if (values.sendOption === "message") {
                  formData.append("template_type", "text");
                }

                dispatch(addCampaign(formData))
                  .then((res) => {
                    if (res.payload.success) {
                      // console.log("HAHAHAHAHAH : ", res.payload);
                      dispatch(
                        fetchEventDetail({ offset: currentPage, limit, id })
                      );
                      setSnackbarMessage(res.payload.message);
                      setSnackbarVariant("toast-success");
                      setShowSnackbar(true);
                      resetForm();
                      toggleAddModal();
                      navigate(
                        `/customers/${client_id}/add-campaign/${res.payload.data.id}`
                      );
                    } else {
                      setSnackbarMessage(res.payload.message);
                      setErrors(res.payload.data);
                      setSnackbarVariant("toast-danger");
                      setShowSnackbar(true);
                    }
                  })
                  .catch((error) => {
                    setSnackbarMessage(
                      "Failed to add campaign: " + error.message
                    );
                    setSnackbarVariant("toast-danger");
                    setShowSnackbar(true);
                  })
                  .finally(() => {
                    setIsSubmitting(false);
                  });
              }}
            >
              {({
                errors,
                touched,
                handleChange,
                // isSubmitting,
                submitForm,
                values,
              }) => (
                <>
                  <div className="d-flex gap-3 mb-3">
                    <FormCheck
                      type="radio"
                      label="Send with Document"
                      name="sendOption"
                      id="sendInvitation"
                      value="invitation"
                      checked={values.sendOption === "invitation"}
                      onChange={handleChange}
                      className={`${
                        errors.sendOption && touched.sendOption
                          ? "is-invalid"
                          : ""
                      } `}
                    />
                    <FormCheck
                      type="radio"
                      label="Only Message"
                      name="sendOption"
                      id="sendMessage"
                      value="message"
                      checked={values.sendOption === "message"}
                      onChange={handleChange}
                      className={`${
                        errors.sendOption && touched.sendOption
                          ? "is-invalid"
                          : ""
                      }`}
                    />

                    {errors.sendOption && touched.sendOption ? (
                      <div className="invalid-feedback">
                        {errors.sendOption}
                      </div>
                    ) : null}
                  </div>

                  <Field
                    name="name"
                    type="text"
                    className={`form-control ${
                      errors.name && touched.name ? "is-invalid" : ""
                    }`}
                    placeholder="Enter campaign name"
                    onChange={handleChange}
                  />
                  {errors.name && touched.name ? (
                    <div className="invalid-feedback">{errors.name}</div>
                  ) : null}
                  <br />
                  <ModalFooter className="d-flex justify-content-end">
                    <div className="d-flex align-items-center gap-3">
                      {currentStep === 2 && (
                        <Button
                          variant="primary"
                          onClick={submitForm}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Adding..." : "Add"}
                        </Button>
                      )}
                      <Button variant="secondary" onClick={toggleAddModal}>
                        Cancel
                      </Button>
                    </div>
                  </ModalFooter>
                </>
              )}
            </Formik>
          )}
        </ModalBody>
      </Modal>

      {/* Connect Modal */}
      <Modal
        show={showConnentModal}
        onHide={handleCloseClickConnectModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Connect Device</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {qrCode ? (
            <div className="text-center">
              <img src={qrCode} alt="WhatsApp QR Code" />
              <p>Please open WhatsApp and scan the QR code</p>
            </div>
          ) : (
            <p>Click Connect to Start Scanning...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseClickConnectModal}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={isScanSuccessful}
            onClick={() =>
              handleConnect(
                campaings[0]?.client_event.whatsapp_client.mobile_number
              )
            }
          >
            Connect
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Disconnect Modal */}
      <Modal
        show={showDisconnectConfirmModal}
        onHide={() => setShowDisconnectConfirmModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Disconnect</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Are you sure you want to disconnect?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDisconnectConfirmModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmDisconnect}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Disconnecting..." : "Disconnect"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal */}
      {/* <Modal
        show={showDeleteConfirmModal}
        onHide={() => setShowDeleteConfirmModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this campaign?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteConfirmModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmDelete}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Deleting..." : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal> */}

      <DeletePopup
        show={showDeleteConfirmModal}
        onHide={() => setShowDeleteConfirmModal(false)}
        title="Confirm Delete?"
        content="Are you sure you want to delete this campaign?"
        removeTitle="Cancel"
        onClickSave={handleConfirmDelete}
        saveTitle={isSubmitting ? "Deleting..." : "Delete"}
      />

      {showEditModal && (
        <Edit
          show={showEditModal}
          handleClose={() => handleEditClose()}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          searchTerm={searchTerm}
          limit={limit}
          id={id}
          currentCustomer={event} // Pass the current customer data to the Edit component
          setShowSnackbar={setShowSnackbar}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarVariant={setSnackbarVariant}
        />
      )}
    </>
  );
}
