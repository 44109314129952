import React, { useCallback, useEffect } from "react";
import CommonLayout from "../../../container/layouts/master/index";
import { fetchDashboardData } from "../../../store/eventsSlice";
import { Col, Image, OverlayTrigger, Row } from "react-bootstrap";
import { Tooltip as TT } from "react-bootstrap";
import {
  // Bar,
  Doughnut,
} from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { useLoader } from "../../../context/LoaderContext";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import moment from "moment";
// import AdvanceWidgets from "../../../components/widget/advance-widgets/index";
import { AlertTriangle } from "react-feather";
import "./dashboard.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const { setLoading } = useLoader();
  const dispatch = useDispatch();

  const dashboardData = useSelector((state) => state.events?.dashboardData);
  const expiry_date = useSelector((state) => state.auth?.user?.expiry_date);
  // const expiry_date = "2024-09-05";
  console.log("Dashboard: ", dashboardData);

  const daysUntilExpiry = moment(expiry_date).diff(moment(), "days");

  // const [timePeriod, setTimePeriod] = useState("This Week");
  // const [chartData, setChartData] = useState({
  //   labels: [],
  //   datasets: [],
  // });

  // const handleTimePeriodChange = (value) => {
  //   setTimePeriod(value);
  // };

  // Data for different time periods

  // useEffect(() => {
  //   const dataForWeek = [12, 19, 3, 5, 2, 3, 9];
  //   const dataForMonth = [20, 35, 10, 25, 12, 20, 15, 30, 10, 25];
  //   const dataForYear = [150, 200, 250, 300, 350, 200, 100, 400, 150, 200];
  //   const dataForAll = [300, 400, 500, 600, 700, 800, 900];
  //   switch (timePeriod) {
  //     case "This Week":
  //       setChartData({
  //         labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  //         datasets: [
  //           {
  //             label: "New Campaigns",
  //             data: dataForWeek,
  //             backgroundColor: "rgba(255, 99, 132, 0.2)",
  //             borderColor: "rgba(255, 99, 132, 1)",
  //             borderWidth: 2,
  //           },
  //         ],
  //       });
  //       break;
  //     case "This Month":
  //       setChartData({
  //         labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
  //         datasets: [
  //           {
  //             label: "New Campaigns",
  //             data: dataForMonth,
  //             backgroundColor: "rgba(54, 162, 235, 0.2)",
  //             borderColor: "rgba(54, 162, 235, 1)",
  //             borderWidth: 2,
  //           },
  //         ],
  //       });
  //       break;
  //     case "This Year":
  //       setChartData({
  //         labels: [
  //           "Jan",
  //           "Feb",
  //           "Mar",
  //           "Apr",
  //           "May",
  //           "Jun",
  //           "Jul",
  //           "Aug",
  //           "Sep",
  //           "Oct",
  //         ],
  //         datasets: [
  //           {
  //             label: "New Campaigns",
  //             data: dataForYear,
  //             backgroundColor: "rgba(255, 206, 86, 0.2)",
  //             borderColor: "rgba(255, 206, 86, 1)",
  //             borderWidth: 2,
  //           },
  //         ],
  //       });
  //       break;
  //     case "All":
  //       setChartData({
  //         labels: ["2018", "2019", "2020", "2021", "2022", "2023", "2024"],
  //         datasets: [
  //           {
  //             label: "New Campaigns",
  //             data: dataForAll,
  //             backgroundColor: "rgba(75, 192, 192, 0.2)",
  //             borderColor: "rgba(75, 192, 192, 1)",
  //             borderWidth: 2,
  //           },
  //         ],
  //       });
  //       break;
  //     default:
  //       setChartData({
  //         labels: [],
  //         datasets: [],
  //       });
  //       break;
  //   }
  // }, [timePeriod]);

  // const chartOptions = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //   },
  //   scales: {
  //     x: {
  //       display: false,
  //     },
  //     y: {
  //       display: false,
  //     },
  //   },
  // };

  const doughnutData = {
    labels: ["Failed", "In Process", "Pending", "Completed"],
    datasets: [
      {
        data: [
          dashboardData?.total_failed_campaigns || 0,
          dashboardData?.total_in_process_campaigns || 0,
          dashboardData?.total_pending_campaigns || 0,
          dashboardData?.total_completed_campaigns || 0,
          dashboardData?.total_drafted_campaigns || 0,
          dashboardData?.total_scheduled_campaigns || 0,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)", // Red for Failed
          "rgba(54, 162, 235, 0.6)", // Blue for In Process
          "rgba(255, 206, 86, 0.6)", // Yellow for Pending
          "rgba(75, 192, 192, 0.6)", // Green for Completed
          "rgba(153, 102, 255, 0.6)", // Purple for Drafted
          "rgba(255, 159, 64, 0.6)", // Orange for Scheduled
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)", // Border for Drafted
          "rgba(255, 159, 64, 1)", // Border for Scheduled
        ],
        borderWidth: 1,
        cutout: "70%",
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const getDashboardData = useCallback(() => {
    setLoading(true);

    dispatch(fetchDashboardData({}))
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setLoading]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  return (
    <>
      <CommonLayout>
        <div className="p-4">
          <Row className="my-5 g-4 cerdit-history-content">
            <h3>My Credits</h3>

            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card h-100">
                <div className="d-flex align-items-start justify-content-between gap-3">
                  <div>
                    <p className="mb-0 text-capitalize">Total Credits</p>
                    <span>{dashboardData?.total_credits || 0}</span>
                  </div>
                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                </div>
              </div>
            </Col>

            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card h-100">
                <div className="d-flex align-items-start justify-content-between gap-3">
                  <div>
                    <p className="text-capitalize">Total Used Credits</p>
                    <span>
                      {dashboardData?.total_credits -
                        dashboardData?.available_credits || 0}
                    </span>
                  </div>
                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                </div>
              </div>
            </Col>

            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card h-100">
                <div className="d-flex align-items-start justify-content-between gap-3">
                  <div>
                    <p className="text-capitalize">Total Reserved Credits</p>
                    <span>{dashboardData?.reserved_credits || 0}</span>
                  </div>
                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <TT id="tooltip-top">
                          Credits are reserved for scheduled campaigns
                        </TT>
                      }
                    >
                      <Image
                        src="/images/modal-icon/info-icon.svg"
                        alt="info-icon"
                        className="me-1"
                        width={15}
                        height={15}
                      />
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </Col>

            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div
                className={`p-4 rounded-3 cedit-h-card h-100 ${
                  dashboardData?.available_credits < 100 ? "border-warning" : ""
                }`}
                style={{
                  border:
                    dashboardData?.available_credits < 100
                      ? "2px solid red"
                      : "2px solid transparent",
                }}
              >
                <div className="d-flex align-items-start justify-content-between gap-3">
                  <div>
                    <p className="text-capitalize">Available Credits</p>
                    <span>{dashboardData?.available_credits || 0}</span>
                  </div>
                  <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                </div>
              </div>
            </Col>

            <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card h-100">
                <div className="d-flex align-items-start justify-content-between gap-3 position-relative">
                  <div className=" ">
                    <p className="text-capitalize">Expire On</p>
                    <span>{moment(expiry_date).format("DD MMMM, YYYY")}</span>
                  </div>
                  {daysUntilExpiry <= 10 && (
                    <div className="credit-h-icon d-flex align-items-center justify-content-center">
                      <AlertTriangle size={24} color="red"></AlertTriangle>
                    </div>
                  )}
                </div>
                {daysUntilExpiry <= 10 && (
                  <div className="scrolling-text-container pt-3">
                    <p className="scrolling-text">
                      Your account is expiring in {daysUntilExpiry} days!
                    </p>
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Row className="my-5 g-4 cerdit-history-content gap-4">
            <h3>Customers</h3>
            <Row>
              <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
                <div className="p-4 rounded-3 cedit-h-card h-100">
                  <div className="d-flex align-items-start justify-content-between gap-3">
                    <div>
                      <p className="mb-0 text-capitalize">Total</p>
                      <span>{dashboardData?.total_customers}</span>
                    </div>
                    <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                  </div>
                </div>
              </Col>
              <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
                <div className="p-4 rounded-3 cedit-h-card h-100">
                  <div className="d-flex align-items-start justify-content-between gap-3">
                    <div>
                      <p className="text-capitalize">Connected</p>
                      <span>{dashboardData?.total_active_customers}</span>
                    </div>
                    <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                  </div>
                </div>
              </Col>
              <Col xxl={3} xl={4} lg={4} md={6} xs={12}>
                <div className="p-4 rounded-3 cedit-h-card h-100">
                  <div className="d-flex align-items-start justify-content-between gap-3">
                    <div>
                      <p className="text-capitalize">Not Connected</p>
                      <span>{dashboardData?.total_inactive_customers}</span>
                    </div>
                    <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xxl={3} xl={6} lg={4} md={6} xs={12}>
                <div className="p-4 rounded-3 cedit-h-card h-100">
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="d-flex align-items-start justify-content-between gap-3">
                      <div>
                        <p className="text-capitalize">Campaigns</p>
                        <span>{dashboardData?.total_campaigns}</span>
                      </div>
                      <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                    </div>
                  </div>
                  {dashboardData?.total_campaigns ? (
                    <>
                      <div style={{ height: "200px", position: "relative" }}>
                        <Doughnut
                          data={doughnutData}
                          options={doughnutOptions}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            textAlign: "center",
                          }}
                        >
                          <p style={{ margin: 0, fontSize: "24px" }}>
                            {dashboardData?.total_campaigns || 2758}
                          </p>
                          <p style={{ margin: 0, fontSize: "12px" }}>Total</p>
                        </div>
                      </div>
                      <div className="mt-3">
                        <ul style={{ listStyle: "none", padding: 0 }}>
                          <li className="d-flex align-items-center">
                            <div
                              style={{
                                width: "4px",
                                height: "20px",
                                backgroundColor: "rgba(255, 99, 132, 1)",
                                marginRight: "8px",
                              }}
                            ></div>
                            Failed: {dashboardData?.total_failed_campaigns || 0}
                          </li>
                          <li className="d-flex align-items-center">
                            <div
                              style={{
                                width: "4px",
                                height: "20px",
                                backgroundColor: "rgba(255, 206, 86, 1)",
                                marginRight: "8px",
                              }}
                            ></div>
                            Inprogress:{" "}
                            {dashboardData?.total_in_process_campaigns || 0}
                          </li>
                          <li className="d-flex align-items-center">
                            <div
                              style={{
                                width: "4px",
                                height: "20px",
                                backgroundColor: "rgba(75, 192, 192, 1)",
                                marginRight: "8px",
                              }}
                            ></div>
                            Pending:{" "}
                            {dashboardData?.total_pending_campaigns || 0}
                          </li>
                          <li className="d-flex align-items-center">
                            <div
                              style={{
                                width: "4px",
                                height: "20px",
                                backgroundColor: "rgba(54, 162, 235, 1)",
                                marginRight: "8px",
                              }}
                            ></div>
                            Completed:{" "}
                            {dashboardData?.total_completed_campaigns || 0}
                          </li>
                          <li className="d-flex align-items-center">
                            <div
                              style={{
                                width: "4px",
                                height: "20px",
                                backgroundColor: "rgba(153, 102, 255, 1)",
                                marginRight: "8px",
                              }}
                            ></div>
                            Drafted:{" "}
                            {dashboardData?.total_drafted_campaigns || 0}
                          </li>
                          <li className="d-flex align-items-center">
                            <div
                              style={{
                                width: "4px",
                                height: "20px",
                                backgroundColor: "rgba(255, 159, 64, 1)",
                                marginRight: "8px",
                              }}
                            ></div>
                            Scheduled:{" "}
                            {dashboardData?.total_scheduled_campaigns || 0}
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Row>

          <Row className="my-5 g-4 cerdit-history-content">
            <Col xxl={3} xl={6} lg={4} md={6} xs={12}>
              <div className="p-4 rounded-3 cedit-h-card h-100">
                <div className="d-flex align-items-start justify-content-between">
                  <div className="d-flex align-items-start justify-content-between gap-3">
                    <div>
                      <p className="text-capitalize">Campaigns</p>
                      <span>{dashboardData?.total_campaigns}</span>
                    </div>
                    <div className="credit-h-icon d-flex align-items-center justify-content-center rounded-1"></div>
                  </div>
                </div>
                {dashboardData?.total_campaigns ? (
                  <>
                    <div style={{ height: "200px", position: "relative" }}>
                      <Doughnut data={doughnutData} options={doughnutOptions} />
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          textAlign: "center",
                        }}
                      >
                        <p style={{ margin: 0, fontSize: "24px" }}>
                          {dashboardData?.total_campaigns || 2758}
                        </p>
                        <p style={{ margin: 0, fontSize: "12px" }}>Total</p>
                      </div>
                    </div>
                    <div className="mt-3">
                      <ul style={{ listStyle: "none", padding: 0 }}>
                        <li className="d-flex align-items-center">
                          <div
                            style={{
                              width: "4px",
                              height: "20px",
                              backgroundColor: "rgba(255, 99, 132, 1)",
                              marginRight: "8px",
                            }}
                          ></div>
                          Failed: {dashboardData?.total_failed_campaigns || 0}
                        </li>
                        <li className="d-flex align-items-center">
                          <div
                            style={{
                              width: "4px",
                              height: "20px",
                              backgroundColor: "rgba(255, 206, 86, 1)",
                              marginRight: "8px",
                            }}
                          ></div>
                          Inprogress:{" "}
                          {dashboardData?.total_in_process_campaigns || 0}
                        </li>
                        <li className="d-flex align-items-center">
                          <div
                            style={{
                              width: "4px",
                              height: "20px",
                              backgroundColor: "rgba(75, 192, 192, 1)",
                              marginRight: "8px",
                            }}
                          ></div>
                          Pending: {dashboardData?.total_pending_campaigns || 0}
                        </li>
                        <li className="d-flex align-items-center">
                          <div
                            style={{
                              width: "4px",
                              height: "20px",
                              backgroundColor: "rgba(54, 162, 235, 1)",
                              marginRight: "8px",
                            }}
                          ></div>
                          Completed:{" "}
                          {dashboardData?.total_completed_campaigns || 0}
                        </li>
                        <li className="d-flex align-items-center">
                          <div
                            style={{
                              width: "4px",
                              height: "20px",
                              backgroundColor: "rgba(153, 102, 255, 1)",
                              marginRight: "8px",
                            }}
                          ></div>
                          Drafted: {dashboardData?.total_drafted_campaigns || 0}
                        </li>
                        <li className="d-flex align-items-center">
                          <div
                            style={{
                              width: "4px",
                              height: "20px",
                              backgroundColor: "rgba(255, 159, 64, 1)",
                              marginRight: "8px",
                            }}
                          ></div>
                          Scheduled:{" "}
                          {dashboardData?.total_scheduled_campaigns || 0}
                        </li>
                      </ul>
                    </div>
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>

        {/* <div className="grid gap-4 advance-widgets-container">
          <AdvanceWidgets
            icon="1"
            className="bg-white g-col-3"
            value={dashboardData?.total_credits || 0}
            title="Total Credits"
          />
          <AdvanceWidgets
            icon="1"
            className="bg-white g-col-3"
            value={
              dashboardData?.total_credits - dashboardData?.available_credits ||
              0
            }
            title="Total Used Credits"
          />
          <AdvanceWidgets
            icon="1"
            className="bg-white g-col-3"
            value={dashboardData?.reserved_credits || 0}
            title="Total Reserved Credits"
          />
          <AdvanceWidgets
            icon="1"
            className="bg-white g-col-3"
            value={dashboardData?.available_credits || 0}
            title="Available Credits"
          />
          <AdvanceWidgets
            icon="1"
            className="bg-white g-col-3"
            value="Expire On"
            title={moment(expiry_date).format("DD MMMM, YYYY")}
          />
        </div> */}
      </CommonLayout>
    </>
  );
};

export default Dashboard;
